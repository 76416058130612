import React from 'react'
import values from 'lodash/values'
import sortBy from 'lodash/sortBy'
import { Flex, Text, useColorModeValue, useToast } from '@chakra-ui/react'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import VideoDetailShortlistRow from 'components/Tables/VideoDetailShortlistRow'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { db } from '_firebase'
import { doc, updateDoc } from 'firebase/firestore'

import { isEmpty } from 'lodash'
import {
  sortVideoItems,
  reorderList,
  convertVideoItemsListToMap,
  removeItemFromList,
  removeUndefinedFromObject,
} from './utils'

function Results({ videoDetails, setVideoDetails, videoFlowID }) {
  const toast = useToast()
  const textColor = useColorModeValue('gray.700', 'white')

  // Sort the results by result index, used to render results on the ui
  const results = values(videoDetails.results)
  const orderedResults = sortBy(results, (result) =>
    Number(result.resultIndex.replace('result', ''))
  )

  // Save modified video object to firestopre
  const saveVideoDetails = (updatedVidedetails) => {
    const videoRef = doc(db, 'videos', videoFlowID)
    return updateDoc(videoRef, updatedVidedetails)
  }

  /**
   * Filter video items object, remove undefined field values
   */
  const filterVideoItemsObj = (videoItemsMap) => {
    const videoDetailsMap = {
      ...videoDetails,
      assets: isEmpty(videoItemsMap.assets) ? undefined : videoItemsMap.assets,
      generatedAudio: isEmpty(videoItemsMap.generatedAudio)
        ? undefined
        : videoItemsMap.generatedAudio,
      results: isEmpty(videoItemsMap.results)
        ? undefined
        : videoItemsMap.results,
      script: isEmpty(videoItemsMap.script) ? undefined : videoItemsMap.script,
    }

    return removeUndefinedFromObject(videoDetailsMap)
  }

  /**
   * Re-order video items (ssets, generatedAudio, results and script) and save to the firestore
   */
  const handleDragEnd = async (result) => {
    const { destination, source } = result

    if (!destination) return

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return

    /**
     * Sort assets, generatedAudio, results and script based on resultIndex
     */
    const sortedVideoItems = sortVideoItems(videoDetails)

    /**
     * Update the order of assets, generatedAudio, results and script, based on sorce and destination index
     */
    const newAssets = reorderList(
      sortedVideoItems.assets,
      source.index,
      destination.index,
      (item) => item
    )

    const newAudio = reorderList(
      sortedVideoItems.generatedAudio,
      source.index,
      destination.index,
      (item) => item
    )

    const newResults = reorderList(
      sortedVideoItems.results,
      source.index,
      destination.index,
      (item, index) => ({
        ...item,
        resultIndex: `result${index + 1}`,
      })
    )

    const newScript = reorderList(
      sortedVideoItems.script,
      source.index,
      destination.index,
      (item) => item
    )

    /**
     * Convert the re-ordered video items (assets, generatedAudio, results and script) to map
     */
    const videoItemsMap = convertVideoItemsListToMap(
      {
        assets: newAssets,
        generatedAudio: newAudio,
        results: newResults,
        script: newScript,
      },
      videoDetails
    )

    const newVideoDetails = filterVideoItemsObj(videoItemsMap)

    // Optimistic update
    setVideoDetails(newVideoDetails)

    try {
      await saveVideoDetails(newVideoDetails)

      toast({
        title: 'Results reordering',
        description: 'Operation is completed successfuly.',
        duration: 5000,
        isClosable: true,
        status: 'success',
      })
    } catch (err) {
      toast({
        title: 'Stock reordering',
        description: 'Operation is failed.',
        duration: 5000,
        isClosable: true,
        status: 'error',
      })

      // Revert state back
      setVideoDetails(videoDetails)
    }
  }

  /**
   * Delete result
   */
  const onRemoveResult = async (resultIndex) => {
    /**
     * Index of the item that needs to be removed, the result items displayed on the screen
     * needs to be sorted by resultIndex, in order for this functionality to work
     */
    if (!resultIndex) return
    const indexNumber = Number(resultIndex.replace('result', ''))
    if (!indexNumber) return
    const index = indexNumber - 1

    /**
     * Sort assets, generatedAudio, results and script based on resultIndex
     */
    const sortedVideoItems = sortVideoItems(videoDetails)

    /**
     * Remove selected result index from assets, generatedAudio, results and script
     */
    const newAssets = removeItemFromList(sortedVideoItems.assets, index)
    const newAudio = removeItemFromList(sortedVideoItems.generatedAudio, index)
    const newResults = removeItemFromList(sortedVideoItems.results, index).map(
      (item, index) => ({
        ...item,
        resultIndex: `result${index + 1}`,
      })
    )
    const newScript = removeItemFromList(sortedVideoItems.script, index)

    /**
     * Convert the re-ordered video items (assets, generatedAudio, results and script) to map
     */
    const videoItemsMap = convertVideoItemsListToMap(
      {
        assets: newAssets,
        generatedAudio: newAudio,
        results: newResults,
        script: newScript,
      },
      videoDetails
    )

    const newVideoDetails = filterVideoItemsObj(videoItemsMap)

    // Optimistic update
    setVideoDetails(newVideoDetails)

    try {
      await saveVideoDetails(newVideoDetails)

      toast({
        title: 'Removing Result',
        description: 'Operation is completed successfuly.',
        duration: 5000,
        isClosable: true,
        status: 'success',
      })
    } catch (err) {
      toast({
        title: 'Removing Result',
        description: 'Operation is failed.',
        duration: 5000,
        isClosable: true,
        status: 'error',
      })

      // Revert state back
      setVideoDetails(videoDetails)
    }
  }

  // Render results UI
  if (!orderedResults) return null

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Card my={{ lg: '24px' }} me={{ lg: '24px' }}>
        <Flex direction="column">
          <CardHeader py="12px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Shortlisted Results
            </Text>
          </CardHeader>

          <CardBody>
            <Droppable droppableId="resultIndex">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <Flex direction="column">
                    {orderedResults.map((row, index) => (
                      <Draggable
                        // draggableId and key should same
                        key={row.id}
                        draggableId={row.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <VideoDetailShortlistRow
                              thumbnail={row.thumbnail}
                              name={row.name}
                              images={row.images}
                              description={
                                videoDetails.generatedDescriptions
                                  ? videoDetails.generatedDescriptions[row.id]
                                  : ''
                              }
                              address={row.address}
                              url={row.url}
                              reviewCount={row.review_count}
                              rating={row.score}
                              resultIndex={row.resultIndex}
                              rowIndex={row.index}
                              score={row.calculatedScore}
                              removeResult={onRemoveResult}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Flex>
                </div>
              )}
            </Droppable>
          </CardBody>
        </Flex>
      </Card>
    </DragDropContext>
  )
}

export default Results
