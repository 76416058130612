import { Flex, Grid, useDisclosure, useToast } from '@chakra-ui/react'
import { db } from '_firebase'
import { doc, getDoc, onSnapshot } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { ViewResultsModal } from 'modals/ViewResultsModal'
import { PackageVideoModal } from 'modals/PackageVideoModal'
import { useParams } from 'react-router-dom'
import { makeAuthenticatedRequest, getEndpointUrl } from 'utils'
import Results from 'components/videoDetails/results'
import Script from 'components/videoDetails/script'
import Header from 'components/videoDetails/header'
import GenerateThumbnailModal from 'modals/generateTumbnailModal'
import { VideoPreview } from 'components/videoDetails/VideoPreview'
import { ThumbnailPreview } from 'components/videoDetails/ThumbnailPreview'
import Affiliate from 'components/videoDetails/Affiliate'
import { useSteps } from 'chakra-ui-steps'
import { videoGenerationSteps } from 'components/videoDetails/utils'

function VideoDetail() {
  const [state, setState] = useState({
    videoDetails: undefined,
    videoFlowDetails: {},
    jobDetails: {},
    isLoading: false,
  })

  const { videoDetails, videoFlowDetails, jobDetails, isLoading } = state

  /**
   * This is used to control the steps UI
   */
  const { nextStep, prevStep, setStep, activeStep } = useSteps({
    initialStep: -1,
  })

  /**
   * This is a video id used to fetch video details
   */
  const { id: videoFlowID } = useParams()

  /**
   * Used to control results modal
   */
  const {
    isOpen: isResultsOpen,
    onOpen: onResultsOpen,
    onClose: onResultsClose,
  } = useDisclosure()

  /**
   * Used to control package video modal
   */
  const {
    isOpen: isPackageOpen,
    onOpen: onPackageOpen,
    onClose: onPackageClose,
  } = useDisclosure()

  /**
   * Used to control generate thumbnail modal
   */

  const {
    isOpen: isThumbnailOpen,
    onOpen: onThumbnailOpen,
    onClose: onThumbnailClose,
  } = useDisclosure()

  const updateState = (newState) => {
    setState((oldState) => ({
      ...oldState,
      ...newState,
    }))
  }

  const toast = useToast()

  const startNextStep = (parameters) => {
    updateState({
      isLoading: true,
    })

    const activeStepDetails = videoGenerationSteps.find(
      (step) => step.step === activeStep
    )

    if (!activeStepDetails) {
      toast({
        title: 'Operation Failed',
        description: 'Something went wrong, please try again or contact us',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })

      updateState({
        isLoading: false,
      })

      return
    }

    makeAuthenticatedRequest(
      getEndpointUrl(activeStepDetails.endpointId),
      'POST',
      parameters
    )
      .then((response) => response.json())
      .then((data) => {
        toast({
          title: data.message,
          description: 'This may a few minutes to complete.',
          status: data.status === 200 ? 'success' : 'error',
          duration: 5000,
          isClosable: true,
        })
        // Don't update loading state and step state here, the above operation will update the videoFlowDetails modal and that will trigger the subscription
      })
      .catch(() => {
        toast({
          title: 'Operation Failed',
          description: 'Something went wrong, please try again or contact us',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        updateState({
          isLoading: false,
        })
      })
  }

  /**
   * Used to add subscriptions when component mounts
   */
  useEffect(() => {
    const getVideoDetails = async (videoFlowDetails) => {
      updateState({
        videoFlowDetails,
      })
      if (videoFlowDetails.jobs) {
        const jobDetails = {}
        // eslint-disable-next-line no-restricted-syntax
        for (const jobID of Object.keys(videoFlowDetails.jobs)) {
          const jobRef = doc(db, 'jobs', jobID)
          // eslint-disable-next-line no-await-in-loop
          const jobSnap = await getDoc(jobRef)
          jobDetails[jobID] = jobSnap.data()
          onSnapshot(doc(db, 'jobs', jobID), (doc) => {
            const updatedJob = doc.data()
            if (updatedJob) {
              const updatedJobDetails = { ...jobDetails, [jobID]: updatedJob }
              updateState({
                jobDetails: updatedJobDetails,
              })
            }
          })
        }
        updateState({
          jobDetails,
        })
      }

      // Find the index of the active step based on status
      const step = videoGenerationSteps.findIndex(
        (step) => step.status1 === videoFlowDetails.status
      )
      // If step is found then it means that the active process is completed so we can stop the spinner
      if (step >= 0) {
        updateState({ isLoading: false })
        setStep(step)
      } else {
        // The active process is not done yet, so show the appropriate step in the ui and don't stop spinner
        const step = videoGenerationSteps.findIndex(
          (step) => step.status2 === videoFlowDetails.status
        )
        updateState({ isLoading: true })
        setStep(step)
      }
    }

    const videoFlowSubscriber = onSnapshot(
      doc(db, 'videoFlow', videoFlowID),
      (doc) => {
        const videoFlowDetails = doc.data()
        if (videoFlowDetails) getVideoDetails(videoFlowDetails)
      }
    )
    /**
     * video subscription
     */
    const videoSubscriber = onSnapshot(
      doc(db, 'videos', videoFlowID),
      (doc) => {
        const videoDetails = doc.data()

        if (videoDetails) {
          updateState({
            videoDetails,
          })
        }
      }
    )

    return () => {
      videoFlowSubscriber()
      videoSubscriber()
    }
  }, [])

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Header
        videoFlowDetails={videoFlowDetails}
        jobDetails={jobDetails}
        isLoading={isLoading}
        videoFlowID={videoFlowID}
        startNextStep={startNextStep}
        onResultsOpen={onResultsOpen}
        onPackageOpen={onPackageOpen}
        onThumbnailOpen={onThumbnailOpen}
        nextStep={nextStep}
        prevStep={prevStep}
        activeStep={activeStep}
      />

      <Grid templateColumns={{ sm: '1fr', lg: '1.6fr 1.2fr' }}>
        {videoFlowDetails && videoFlowDetails.videoURL ? (
          <VideoPreview url={videoFlowDetails.videoURL} />
        ) : null}
        {videoFlowDetails && videoFlowDetails.thumbnail ? (
          <ThumbnailPreview url={videoFlowDetails.thumbnail} />
        ) : null}
      </Grid>
      <Grid templateColumns={{ sm: '1fr', lg: '1.6fr 1.2fr' }}>
        {videoDetails && videoFlowDetails.videoURL ? (
          <Affiliate
            videoDetails={videoDetails}
            verticalID={videoFlowDetails.verticalID}
          />
        ) : null}
      </Grid>
      <Grid templateColumns={{ sm: '1fr', lg: '1.6fr 1.2fr' }}>
        {videoDetails ? (
          <>
            <Results
              videoDetails={videoDetails}
              setVideoDetails={(updatedDetails) => {
                updateState({ videoDetails: updatedDetails })
              }}
              videoFlowID={videoFlowID}
            />
            <Script videoDetails={videoDetails} videoFlowID={videoFlowID} />
          </>
        ) : null}
      </Grid>

      <ViewResultsModal
        videoFlowID={videoFlowID}
        isOpen={isResultsOpen}
        onClose={onResultsClose}
      />
      <PackageVideoModal
        videoFlowID={videoFlowID}
        isOpen={isPackageOpen}
        onClose={onPackageClose}
      />
      <GenerateThumbnailModal
        isOpen={isThumbnailOpen}
        onClose={onThumbnailClose}
        videoFlowID={videoFlowID}
      />
    </Flex>
  )
}

export default VideoDetail
