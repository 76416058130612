import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from './index'

export const emailPasswordSignin = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    )

    return {
      status: 'success',
      user: userCredential.user,
    }
  } catch (err) {
    let message = ''

    switch (err.code) {
      case 'auth/user-not-found':
        message = `User with email ${email} does not exist`
        break

      case 'auth/wrong-password':
        message = 'Invalid email or password'
        break

      default:
        message = err.message
    }

    return {
      status: 'error',
      message,
    }
  }
}

export const logout = () => {
  auth.signOut()
}
