/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Image } from '@chakra-ui/react'

const settings = {
  dots: true,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  dotsClass: 'slick-dots-script',

  customPaging: () => <div className="app-slick-dot" />,
}

function AssetsSlider({ assets }) {
  return (
    <Slider {...settings}>
      {assets.map((asset) => (
        <Image
          src={asset.url}
          objectFit="cover"
          maxW="100%"
          width="100%"
          key={asset.order}
          borderRadius={10}
        />
      ))}
    </Slider>
  )
}

export default AssetsSlider
