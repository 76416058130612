// chakra imports
import { Box, Portal } from '@chakra-ui/react'
import Footer from 'components/Footer/Footer'
// core components
import AuthNavbar from 'components/Navbars/AuthNavbar'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import routes from 'routes'

export default function Pages() {
  React.useEffect(() => {
    document.body.style.overflow = 'unset'
    // Specify how to clean up after this effect:
    return function cleanup() {}
  })

  document.documentElement.dir = 'ltr'

  function getRoutes(routes) {
    // return routes.map();
    return routes
      .filter((route) => route.layout === '/auth')
      .map((route) => (
        <Route
          key={route.path}
          path={`${route.layout}${route.path}`}
          component={route.component}
        />
      ))
  }

  return (
    <Box w="100%">
      <Portal>
        <AuthNavbar logoText="" />
      </Portal>
      <Box w="100%">
        <Box w="100%">
          <Switch>{getRoutes(routes)}</Switch>
        </Box>
      </Box>
      <Box px="24px" mx="auto" width="1044px" maxW="100%" mt="60px">
        <Footer />
      </Box>
    </Box>
  )
}
