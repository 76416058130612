import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import AuthLayout from 'layouts/Auth'
import AdminLayout from 'layouts/Admin'
import { PrivateRoute } from './privateRoute'

export function Routes() {
  return (
    <Switch>
      <Route path="/auth">
        <AuthLayout />
      </Route>
      <PrivateRoute path="/admin">
        <AdminLayout />
      </PrivateRoute>
      <Redirect from="/" to="/admin" />
    </Switch>
  )
}
