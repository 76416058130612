// import
import React from 'react'
import Dashboard from 'views/Dashboard/Dashboard'
import { Videos } from 'views/Dashboard/videos'
import Billing from 'views/Dashboard/Billing'
import SignIn from 'views/Pages/SignIn'

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  DocumentIcon,
} from 'components/Icons/Icons'
import VideoDetail from 'views/Dashboard/VideoDetail'
import Profile from 'views/Dashboard/Profile'

const dashRoutes = [
  // Admin Routes
  {
    path: '/profile',
    name: 'Profile',
    rtlName: 'لوحة القيادة',
    icon: <HomeIcon color="inherit" />,
    component: Profile,
    layout: '/admin',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    rtlName: 'لوحة القيادة',
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/videos/:id',
    layout: '/admin',
    name: 'Video Details',
    component: VideoDetail,
    hidden: true,
  },
  {
    path: '/videos',
    name: 'Videos',
    rtlName: 'لوحة القيادة',
    icon: <StatsIcon color="inherit" />,
    component: Videos,
    layout: '/admin',
  },
  {
    path: '/jobs',
    name: 'Jobs',
    rtlName: 'لوحة القيادة',
    icon: <CreditIcon color="inherit" />,
    component: Profile,
    layout: '/admin',
  },
  {
    path: '/results',
    name: 'Results',
    rtlName: 'لوحة القيادة',
    icon: <CreditIcon color="inherit" />,
    component: Billing,
    layout: '/admin',
  },
  {
    path: '/sources',
    name: 'Sources',
    rtlName: 'لوحة القيادة',
    icon: <CreditIcon color="inherit" />,
    component: Profile,
    layout: '/admin',
  },
  {
    path: '/verticals',
    name: 'Verticals',
    rtlName: 'لوحة القيادة',
    icon: <CreditIcon color="inherit" />,
    component: Profile,
    layout: '/admin',
  },

  // Auth Routes
  {
    path: '/signin',
    name: 'Signin',
    rtlName: 'لوحة القيادة',
    icon: <DocumentIcon color="inherit" />,
    component: SignIn,
    layout: '/auth',
  },
]
export default dashRoutes
