import {
  IconButton,
  Image,
  Stack,
  Heading,
  Progress,
  Text,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { FaRegTimesCircle, FaPlay, FaPause } from 'react-icons/fa'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { assetsStorage } from '_firebase'

function FileUploadUI({ file, videoFlowID, onComplete, order, onCancel }) {
  const [state, setState] = useState({
    progress: 0,
    isPaused: false,
  })

  const uploadTask = useRef(null)

  const toast = useToast()

  const updateState = (newState) => {
    setState((oldState) => ({
      ...oldState,
      ...newState,
    }))
  }

  const { progress, isPaused } = state

  const mediaType = file.file.type.split('/')[0]

  let preview = null

  if (mediaType === 'image') {
    preview = (
      <Image
        objectFit="cover"
        maxW="100px"
        src={URL.createObjectURL(file.file)}
      />
    )
  } else if (mediaType === 'video') {
    preview = (
      <video
        controls
        style={{
          maxWidth: '100px',
          height: 'auto',
        }}
      >
        <track kind="captions" />
        <source src={URL.createObjectURL(file.file)} />
      </video>
    )
  }

  const handlePause = () => {
    uploadTask.current.pause()
  }

  const handleResume = () => {
    uploadTask.current.resume()
  }

  const handleCancel = () => {
    uploadTask.current.cancel()
    setTimeout(() => {
      onCancel(file.id)
    }, 1000)
  }

  useEffect(() => {
    const nameSplit = file.file.name.split('.')
    const ext = nameSplit[nameSplit.length - 1]
    const filePath = `${videoFlowID}/stock/${file.id}.${ext}`
    const fileRef = ref(assetsStorage, filePath)

    uploadTask.current = uploadBytesResumable(fileRef, file.file)
    const unsubscribe = uploadTask.current.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.floor(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )
        let isPaused = false

        if (snapshot.state === 'paused') {
          isPaused = true
        } else if (snapshot.state === 'running') {
          isPaused = false
        }

        updateState({
          progress,
          isPaused,
        })
      },
      () => {
        toast({
          title: file.file.name,
          description: 'Cenceled uploading',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },

      () => {
        getDownloadURL(uploadTask.current.snapshot.ref).then((downloadUrl) => {
          toast({
            title: file.file.name,
            description: 'Uploaded succssfully',
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
          onComplete({
            type: mediaType,
            url: downloadUrl,
            order,
            id: file.id,
          })
        })
      }
    )

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <Stack direction="row" mb="10px">
      {preview}
      <Stack direction="column" flexGrow={1} flexShrink={1}>
        <Heading size="xs">{file.file.name}</Heading>
        <Stack direction="row" alignItems="center">
          <Progress
            color="green"
            min={0}
            max={100}
            value={progress}
            size="md"
            flexGrow={1}
            flexShrink={1}
          />
          <Text>{progress}%</Text>
        </Stack>
        <Stack direction="row">
          <IconButton
            size="sm"
            icon={<FaRegTimesCircle />}
            onClick={handleCancel}
          />
          {isPaused ? (
            <IconButton size="sm" icon={<FaPlay />} onClick={handleResume} />
          ) : (
            <IconButton size="sm" icon={<FaPause />} onClick={handlePause} />
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default FileUploadUI
