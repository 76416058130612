import React from 'react'
import { Flex, Text, useColorModeValue } from '@chakra-ui/react'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import VideoDetailScriptRow from './VideoDetailScriptRow'
import { sortVideoItems } from './utils'

function Script({ videoDetails, videoFlowID }) {
  const textColor = useColorModeValue('gray.700', 'white')

  // Sort the script and results by result index

  const { script, results } = sortVideoItems(videoDetails)

  // Render script UI
  if (!results || !script) return null

  let topicScript = ''
  let outroScript = ''
  if (videoDetails.script) {
    topicScript = videoDetails.script.topic
    outroScript = videoDetails.script.outro
  }

  return (
    <Card my="24px" ms={{ lg: '24px' }} style={{ overflow: 'hidden' }}>
      <CardHeader mb="12px">
        <Flex direction="column" w="100%">
          <Flex
            direction={{ sm: 'column', lg: 'row' }}
            justify={{
              sm: 'center',
              lg: 'space-between',
            }}
            align={{ sm: 'center' }}
            w="100%"
            my={{ md: '12px' }}
          >
            <Text
              color={textColor}
              fontSize={{
                sm: 'lg',
                md: 'xl',
                lg: 'lg',
              }}
              fontWeight="bold"
            >
              Script
            </Text>
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex direction="column" w="100%">
          <Text
            color="gray.400"
            fontSize={{ sm: 'sm', md: 'md' }}
            fontWeight="semibold"
            my="12px"
          >
            TOPIC
          </Text>
          {/* Topic Row */}
          <VideoDetailScriptRow
            generatedAudio={
              videoDetails.generatedAudio
                ? videoDetails.generatedAudio.topic
                : null
            }
            text={topicScript}
            showControllButtons={{
              edit: true,
              fileUpload: true,
              preloadedStock: true,
            }}
            videoFlowID={videoFlowID}
            resultIndex="topic"
            key="topic"
            stock={videoDetails.assets ? videoDetails.assets.stock : null}
          />
          <Text
            color="gray.400"
            fontSize={{ sm: 'sm', md: 'md' }}
            fontWeight="semibold"
            my="12px"
          >
            RESULTS
          </Text>
          {/* Results Row */}
          {script.map((row, index) => {
            const result = results[index]

            return (
              <VideoDetailScriptRow
                showControllButtons={{
                  edit: true,
                  refresh: true,
                }}
                generatedAudio={
                  videoDetails.generatedAudio
                    ? videoDetails.generatedAudio[result.resultIndex]
                    : null
                }
                text={row}
                key={result.resultIndex}
                videoFlowID={videoFlowID}
                resultID={result.id}
                resultIndex={result.resultIndex}
                resultDescription={result.description}
                assets={videoDetails.assets[result.resultIndex]}
              />
            )
          })}
          <Text
            color="gray.400"
            fontSize={{ sm: 'sm', md: 'md' }}
            fontWeight="semibold"
            my="12px"
          >
            OUTRO
          </Text>
          {/* Outro row */}
          <VideoDetailScriptRow
            generatedAudio={
              videoDetails.generatedAudio
                ? videoDetails.generatedAudio.outro
                : null
            }
            text={outroScript}
            key="outro"
            showControllButtons={{
              edit: true,
            }}
            videoFlowID={videoFlowID}
            resultIndex="outro"
          />
        </Flex>
      </CardBody>
    </Card>
  )
}

export default Script
