import React, { useEffect, useState } from 'react'
import sortBy from 'lodash/sortBy'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Text, useToast } from '@chakra-ui/react'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '_firebase'
import MediaPreview from './MediaPreview'

function StockAssetsList({ stock, videoFlowID }) {
  const [stockList, setStockList] = useState(stock)
  const toast = useToast()

  useEffect(() => {
    setStockList(stock)
  }, [stock])

  const sortedStock = sortBy(stockList, (f) => f.order)

  const reorderList = (list, startIndex, endIndex) => {
    const newList = [...list]
    const removed = newList.splice(startIndex, 1)[0]
    newList.splice(endIndex, 0, removed)

    return newList.map((item, index) => ({
      ...item,
      order: index,
    }))
  }

  const handleDragEnd = async (result) => {
    const { destination, source } = result

    if (!destination) return

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return

    const newList = reorderList(sortedStock, source.index, destination.index)

    setStockList(newList)

    // Update stock in firebase
    try {
      const videoRef = doc(db, 'videos', videoFlowID)

      await updateDoc(videoRef, {
        'assets.stock': newList,
      })

      toast({
        title: 'Stock reordering',
        description: 'Operation is completed successfuly.',
        duration: 5000,
        isClosable: true,
        status: 'success',
      })
    } catch (err) {
      toast({
        title: 'Stock reordering',
        description: 'Operation is failed.',
        duration: 5000,
        isClosable: true,
        status: 'error',
      })

      // Revert state back
      setStockList(sortedStock)
    }
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Text fontSize="xl" mb={3}>
        Stock Assets
      </Text>
      <Droppable droppableId={videoFlowID}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {sortedStock.map((stock, index) => (
              <MediaPreview
                file={stock}
                key={stock.id}
                videoFlowID={videoFlowID}
                index={index}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default StockAssetsList
