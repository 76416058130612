import React from 'react'
import {
  Grid,
  Box,
  Flex,
  Text,
  Icon,
  Spacer,
  useColorMode,
  useColorModeValue,
  Spinner,
  Button,
} from '@chakra-ui/react'
import { RiYoutubeFill } from 'react-icons/ri'
import { FaFastForward, FaClock } from 'react-icons/fa'
import VideoDetailJobRow from 'components/Tables/VideoDetailJobRow'
import IconBox from 'components/Icons/IconBox'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import BackgroundCard1 from 'assets/img/BackgroundCard1.png'
import { Steps, Step } from 'chakra-ui-steps'
import { videoGenerationSteps } from './utils'

function Header({
  videoFlowDetails,
  jobDetails,
  isLoading,
  videoFlowID,
  startNextStep,
  onResultsOpen,
  onPackageOpen,
  onThumbnailOpen,
  nextStep,
  prevStep,
  activeStep,
}) {
  const { colorMode } = useColorMode()
  const iconBlue = useColorModeValue('blue.500', 'blue.500')
  const textColor = useColorModeValue('gray.700', 'white')

  // Step details based on the active step number
  const stepDetails =
    videoGenerationSteps.find((step) => step.step === activeStep) || {}

  // Step details of next run step, based on the status from firestore, helps determining the steps navigation
  const lastRunStepDetails =
    videoGenerationSteps.find(
      (step) => step.status1 === videoFlowDetails.status
    ) || {}

  const isStepsCompleted = activeStep === videoGenerationSteps.length - 1

  /**
   * Send the text to display in the step label based on loading state
   */
  const getStepLabel = (step) => {
    if (isLoading && step.step === activeStep) return step.status2

    return step.process
  }

  return (
    <>
      <Grid templateColumns={{ sm: '1fr', lg: '2fr 1.2fr' }} templateRows="1fr">
        <Box>
          <Grid
            templateColumns={{
              sm: '1fr',
              md: '1fr 1fr',
              xl: '1fr 1fr 1fr 1fr',
            }}
            templateRows={{
              sm: 'auto auto auto',
              md: '1fr auto',
              xl: '1fr',
            }}
            gap="26px"
          >
            <Card
              backgroundImage={
                colorMode === 'dark'
                  ? 'linear-gradient(180deg, #3182CE 0%, #63B3ED 100%)'
                  : BackgroundCard1
              }
              backgroundRepeat="no-repeat"
              background="cover"
              bgPosition="10%"
              p="16px"
              h={{ sm: '220px', xl: '100%' }}
              gridArea={{
                md: '1 / 1 / 2 / 3',
                xl: '1 / 1 / 2 / 3',
              }}
            >
              <CardBody h="100%" w="100%">
                <Flex
                  direction="column"
                  color="white"
                  h="100%"
                  p="0px 10px 20px 10px"
                  w="100%"
                >
                  <Flex justify="space-between" align="center">
                    <Text fontSize="md" fontWeight="bold">
                      {videoFlowDetails.verticalID}
                    </Text>
                    <Icon
                      as={RiYoutubeFill}
                      w="48px"
                      h="auto"
                      color="gray.400"
                    />
                  </Flex>
                  <Spacer />
                  <Flex direction="column">
                    <Box>
                      <Text
                        fontSize="2xl"
                        letterSpacing="2px"
                        fontWeight="bold"
                      >
                        {videoFlowDetails.parameters
                          ? videoFlowDetails.parameters.cityName
                          : ''}
                      </Text>
                    </Box>
                    <Flex mt="14px">
                      {videoFlowDetails.title ? (
                        <Flex direction="column" me="34px">
                          <Text fontSize="xs">Title</Text>
                          <Text fontSize="xs" fontWeight="bold">
                            {videoFlowDetails.title}
                          </Text>
                        </Flex>
                      ) : null}
                    </Flex>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>

            <Card p="16px" display="flex" align="center" justify="center">
              <CardBody>
                <Flex direction="column" align="center" w="100%" py="14px">
                  <IconBox as="box" h="60px" w="60px" bg={iconBlue}>
                    <Icon h="24px" w="24px" color="white" as={FaClock} />
                  </IconBox>
                  <Flex
                    direction="column"
                    m="14px"
                    justify="center"
                    textAlign="center"
                    align="center"
                    w="100%"
                  >
                    <Text
                      mb="8px"
                      fontSize="xs"
                      color="gray.500"
                      fontWeight="semibold"
                    >
                      Status
                    </Text>
                    <Text
                      mb="8px"
                      fontSize="md"
                      color={textColor}
                      fontWeight="bold"
                    >
                      {/* If isLoading is true, it means that the process is not completed yet so show the status2, otherwise status1  */}
                      {isLoading ? stepDetails.status2 : stepDetails.status1}
                    </Text>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>

            <Card p="16px" display="flex" align="center" justify="center">
              <CardBody>
                <Flex
                  direction="column"
                  align="center"
                  justify="center"
                  w="100%"
                  py="14px"
                >
                  <IconBox as="box" h="60px" w="60px" bg={iconBlue}>
                    <Icon h="24px" w="24px" color="white" as={FaFastForward} />
                  </IconBox>
                  <Flex
                    direction="column"
                    m="12px"
                    justify="center"
                    textAlign="center"
                    align="center"
                    w="100%"
                  >
                    <Text
                      mb="8px"
                      fontSize="xs"
                      color="gray.500"
                      fontWeight="semibold"
                    >
                      Next Step
                    </Text>
                    {isLoading || activeStep < 0 ? (
                      <Flex
                        direction="column"
                        align="center"
                        justify="center"
                        w="100%"
                        py="14px"
                      >
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="blue.500"
                          size="xl"
                        />
                      </Flex>
                    ) : (
                      <Text
                        mb="16px"
                        fontSize="md"
                        color={textColor}
                        fontWeight="bold"
                      >
                        {stepDetails.process}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </Grid>
        </Box>

        <Card p="16px" ml="24px">
          <CardHeader>
            <Flex
              justify="space-between"
              align="center"
              minHeight="60px"
              w="100%"
            >
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                Jobs
              </Text>
              {videoFlowDetails.status !== 'PENDING' && (
                <Button
                  onClick={onResultsOpen}
                  variant={colorMode === 'dark' ? 'primary' : 'outlined'}
                >
                  VIEW RESULTS
                </Button>
              )}
            </Flex>
          </CardHeader>
          <CardBody>
            <Flex direction="column" w="100%">
              {videoFlowDetails.jobs &&
              jobDetails &&
              Object.keys(jobDetails).length ===
                Object.keys(videoFlowDetails.jobs).length
                ? Object.keys(videoFlowDetails.jobs).map((row, key) => (
                    <VideoDetailJobRow
                      id={row}
                      sourceID={jobDetails[row].sourceID}
                      status={jobDetails[row].status}
                      createdAt={jobDetails[row].createdAt}
                      completedAt={jobDetails[row].completedAt}
                      pendingTasks={jobDetails[row].pendingTasks}
                      failedTasks={jobDetails[row].failedTasks}
                      key={key}
                    />
                  ))
                : ''}
            </Flex>
          </CardBody>
        </Card>
      </Grid>

      {/* Steps UI */}
      <Box py="16">
        {/* Change the orientation to vertical */}
        <Steps activeStep={activeStep} mobileBreakpoint="1800px" size="sm">
          {videoGenerationSteps.map((step) => (
            <Step
              label={getStepLabel(step)}
              key={step.step}
              state={isLoading ? 'loading' : undefined}
            />
          ))}
        </Steps>

        {/* Controlls */}
        <Box display="flex" alignItems="center" mt="8">
          {/* Start Button */}
          {stepDetails.endpointId && !isLoading ? (
            <Text fontSize="lg" color={textColor} fontWeight="bold" ml="auto">
              <Button
                variant={colorMode === 'dark' ? 'primary' : 'dark'}
                onClick={() => {
                  if (stepDetails.needsInput) {
                    if (stepDetails.process === 'PACKAGE VIDEO') {
                      onPackageOpen()
                    } else if (stepDetails.process === 'GENERATE THUMBNAIL') {
                      onThumbnailOpen()
                    }
                  } else {
                    startNextStep({
                      videoFlowID,
                    })
                  }
                }}
              >
                START
              </Button>
            </Text>
          ) : null}

          {/* Next and Back buttons */}
          <Box ml="auto">
            {isLoading || activeStep < 0 ? (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="md"
              />
            ) : (
              <>
                <Button
                  onClick={() => prevStep()}
                  isDisabled={activeStep === 0}
                >
                  Back
                </Button>
                <Button
                  onClick={() => nextStep()}
                  isDisabled={
                    isStepsCompleted || stepDetails.isOptional === true
                      ? false
                      : lastRunStepDetails.step <= stepDetails.step
                  }
                >
                  Next
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Header
