import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  useToast,
} from '@chakra-ui/react'
import { collection, getDocs } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
// eslint-disable-next-line no-unused-vars
import { makeAuthenticatedRequest, getEndpointUrl, endpoints } from 'utils'
import { db } from '_firebase'

export function CreateNewVideoModal(props) {
  const { isOpen, onClose } = props
  const [title, setTitle] = useState('')
  const [verticals, setVerticals] = useState({})
  const [selectedVerticalID, setSelectedVerticalID] = useState()
  const [selectedVertical, setSelectedVertical] = useState()
  const [parameters, setParameters] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const createNewVideoFlow = () => {
    setIsLoading(true)

    makeAuthenticatedRequest(getEndpointUrl(endpoints.startVideoFlow), 'POST', {
      title,
      parameters,
      verticalID: selectedVerticalID,
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false)
        if (data) {
          toast({
            title: data.message,
            description: 'This may a few minutes to complete.',
            status: data.status === 200 ? 'success' : 'error',
            duration: 5000,
            isClosable: true,
          })
          onClose()
        }
      })
  }

  useEffect(() => {
    const getVerticals = async () => {
      const collectionRef = collection(db, 'verticals')
      const docSnap = await getDocs(collectionRef)

      const verticalsResults = docSnap.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setVerticals(verticalsResults)
    }
    getVerticals()
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New video</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb="24px" id="title">
            <FormLabel>Title</FormLabel>
            <Input
              type="text"
              onChange={(event) => {
                setTitle(event.target.value)
              }}
            />
            <FormHelperText>
              Select the vertical for the video you want to create.
            </FormHelperText>
          </FormControl>
          {verticals && (
            <FormControl
              id="verticalID"
              mb="24px"
              onChange={(event) => {
                if (event.target.value && event.target.value !== '') {
                  const selected = verticals.filter(
                    (a) => a.id === event.target.value
                  )
                  setSelectedVerticalID(event.target.value)
                  setSelectedVertical(selected[0])
                  const defaultParams = {}
                  // eslint-disable-next-line array-callback-return
                  Object.keys(selected[0].parameters).map((parameter) => {
                    const selectedParameter = selected[0].parameters[parameter]

                    if (selectedParameter.type === 'text') {
                      defaultParams[parameter] = ''
                    } else if (selectedParameter.type === 'boolean') {
                      defaultParams[parameter] = selectedParameter.default
                        ? selectedParameter.default
                        : false
                    } else {
                      defaultParams[parameter] = 0
                    }
                  })
                  setParameters(defaultParams)
                } else {
                  setSelectedVerticalID()
                  setSelectedVertical()
                }
              }}
            >
              <FormLabel>Vertical</FormLabel>
              <Select placeholder="Select vertical">
                {Object.values(verticals).map((vertical, index) => (
                  // eslint-disable-next-line
                  <option key={index}>{vertical.id}</option>
                ))}
              </Select>
              <FormHelperText>
                Select the vertical for the video you want to create.
              </FormHelperText>
            </FormControl>
          )}
          {selectedVertical && selectedVertical.parameters && parameters
            ? Object.keys(selectedVertical.parameters)
                .sort((a, b) => a < b)
                // eslint-disable-next-line array-callback-return, consistent-return
                .map((parameter, index) => {
                  if (selectedVertical.parameters[parameter].type === 'text') {
                    return (
                      <FormControl key={index} id={parameter} mb="24px">
                        <FormLabel>{parameter}</FormLabel>
                        <Input
                          type="text"
                          onChange={(event) => {
                            setParameters({
                              ...parameters,
                              [parameter]: event.target.value,
                            })
                          }}
                        />
                        <FormHelperText>
                          Please enter a {parameter}
                        </FormHelperText>
                      </FormControl>
                    )
                  }
                  if (
                    selectedVertical.parameters[parameter].type === 'boolean'
                  ) {
                    return (
                      <FormControl display="flex" alignItems="center" mb="24px">
                        <FormLabel htmlFor="email-alerts">
                          {parameter} - {parameters[parameter]}
                        </FormLabel>
                        <Switch
                          defaultChecked={
                            selectedVertical.parameters[parameter].default
                          }
                          checked={parameters[parameter]}
                          onChange={(event) => {
                            setParameters({
                              ...parameters,
                              [parameter]: event.target.checked,
                            })
                          }}
                          id={parameter}
                        />
                      </FormControl>
                    )
                  }
                })
            : ''}
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={
              !(
                parameters &&
                title &&
                title !== '' &&
                selectedVerticalID &&
                selectedVerticalID !== '' &&
                !isLoading
              )
            }
            onClick={createNewVideoFlow}
            colorScheme="blue"
            mr={3}
            isLoading={isLoading}
            loadingText="Creating..."
          >
            Create
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
