import {
  Box,
  Flex,
  useColorModeValue,
  IconButton,
  Spinner,
  useToast,
  Select,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import ContentEditable from 'react-contenteditable'
import { TbRefresh, TbEdit, TbCheck, TbX, TbVideo } from 'react-icons/tb'
import React, { useEffect, useRef, useState } from 'react'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import { makeAuthenticatedRequest, getEndpointUrl, endpoints } from 'utils'
import { sortBy } from 'lodash'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { v4 as uuid } from 'uuid'
import { db } from '_firebase'
import { arrayUnion, doc, updateDoc, getDoc } from 'firebase/firestore'
import FileUploadUI from './FileUploadUI'
import AssetsSlider from './assetsSlider'
import StockAssetsList from './stockAssetsList'

function VideoDetailScriptRow(props) {
  const toast = useToast()
  const textColor = useColorModeValue('gray.700', 'white')
  const bgColor = useColorModeValue('#F8F9FA', 'navy.900')
  const fileUploaderInputRef = useRef(null)
  const {
    text,
    generatedAudio,
    showControllButtons,
    videoFlowID,
    resultID,
    resultIndex,
    resultDescription,
    stock,
    assets,
  } = props

  const [state, setState] = useState({
    controllsStatus: 'idle',
    editedText: text,
    files: [],
    preloadedStockAssets: {},
    selectedPreloadedStockVideo: undefined,
  })

  const {
    controllsStatus,
    editedText,
    files,
    preloadedStockAssets,
    selectedPreloadedStockVideo,
  } = state

  const updateState = (newState) => {
    setState((oldState) => ({
      ...oldState,
      ...newState,
    }))
  }

  // Update edited text when text prop is updated
  useEffect(() => {
    updateState({
      editedText: text,
    })
  }, [text])

  // fetch preloaded list of stock videos
  const getPreloadedStockVideos = async () => {
    const stockVideosRef = doc(db, 'assets', 'stock')
    const stockVideos = (await getDoc(stockVideosRef)).data()
    if (stockVideos) {
      updateState({
        preloadedStockAssets: stockVideos,
      })
    }
  }
  useEffect(() => {
    getPreloadedStockVideos()
  }, [])

  const onSelectPreloadedStockVideo = async () => {
    const videoRef = doc(db, 'videos', videoFlowID)
    await updateDoc(videoRef, {
      'assets.stock': arrayUnion({
        type: 'video',
        url: preloadedStockAssets[selectedPreloadedStockVideo].url,
        order: stock && stock.length ? stock.length : 0,
        selectedPreloadedStockVideo,
      }),
    })

    updateState({
      controllsStatus: 'idle',
      selectedPreloadedStockVideo: undefined,
    })
  }

  // Handle script refresh
  const onRegenerateScript = () => {
    updateState({
      controllsStatus: 'loading',
    })

    makeAuthenticatedRequest(
      getEndpointUrl(endpoints.generateScriptForText),
      'POST',
      {
        videoFlowID,
        resultID,
        resultIndex,
        text: resultDescription.trim(),
        regenerateAudio: true,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        updateState({
          controllsStatus: 'idle',
        })
        toast({
          title: data.message,
          description: 'This may a few minutes to complete.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      })
      .catch((err) => {
        updateState({
          controllsStatus: 'idle',
        })

        toast({
          title: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      })
  }

  // Handle edit script
  const onEditScript = () => {
    updateState({
      controllsStatus: 'loading',
    })

    makeAuthenticatedRequest(
      getEndpointUrl(endpoints.updateScriptForText),
      'POST',
      {
        videoFlowID,
        resultIndex,
        text: editedText,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        updateState({
          controllsStatus: 'idle',
        })
        toast({
          title: data.message,
          description: 'This may a few minutes to complete.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      })
      .catch((err) => {
        updateState({
          controllsStatus: 'idle',
        })

        toast({
          title: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      })
  }

  // Handle file upload
  const handleFileUpload = (e) => {
    e.stopPropagation()
    e.preventDefault()

    const targetEl = e.target
    const { files: selectedFiles } = targetEl

    const mappedFiles = []

    let order = 0
    if (files && files.length > 0) {
      order = files[files.length - 1].order + 1
    } else if (stock && stock.length > 0) {
      order = stock[stock.length - 1].order + 1
    }

    for (let i = 0; i < selectedFiles.length; i++) {
      mappedFiles.push({
        id: uuid(),
        file: selectedFiles[i],
        order: order + i,
      })
    }

    updateState({
      files: [...mappedFiles, ...files],
    })
  }

  const removeCompletedTask = (id) => {
    setState((oldState) => {
      const filteredFiles = oldState.files.filter((file) => file.id !== id)

      return {
        ...oldState,
        files: filteredFiles,
      }
    })
  }

  const onUploadComplete = async ({ type, url, order, id }) => {
    const videoRef = doc(db, 'videos', videoFlowID)

    // Remove the completed upload from list
    setState((oldState) => {
      const filteredFiles = oldState.files.filter((file) => file.id !== id)
      return {
        ...oldState,
        files: filteredFiles,
      }
    })

    await updateDoc(videoRef, {
      'assets.stock': arrayUnion({ type, url, order, id }),
    })
  }

  const controlls = {
    // First character is for sorting controlls
    edit: {
      order: 2,
      content: (
        <IconButton
          size="sm"
          icon={<TbEdit />}
          onClick={() => {
            updateState({ controllsStatus: 'edit' })
          }}
        />
      ),
    },

    refresh: {
      order: 3,
      content: (
        <IconButton
          size="sm"
          icon={<TbRefresh />}
          onClick={onRegenerateScript}
        />
      ),
    },

    fileUpload: {
      order: 1,
      content: (
        <>
          <input
            type="file"
            ref={fileUploaderInputRef}
            hidden
            onChange={handleFileUpload}
            multiple
            accept="image/*, video/*"
          />
          <IconButton
            size="sm"
            icon={<AiOutlineCloudUpload />}
            onClick={() => {
              if (fileUploaderInputRef && fileUploaderInputRef.current)
                fileUploaderInputRef.current.click()
            }}
          />
        </>
      ),
    },
    preloadedStock: {
      order: 0,
      content: (
        <IconButton
          size="sm"
          icon={<TbVideo />}
          onClick={() => {
            updateState({ controllsStatus: 'addPreloadedStockVideo' })
          }}
        />
      ),
    },
  }

  let controllsJSX = null

  if (showControllButtons) {
    if (controllsStatus === 'loading')
      controllsJSX = <Spinner width="16px" height="16px" />
    else if (controllsStatus === 'addPreloadedStockVideo') {
      controllsJSX = (
        <>
          {preloadedStockAssets && (
            <Select
              value={selectedPreloadedStockVideo}
              placeholder="Select existing stock"
              onChange={(e) => {
                updateState({ selectedPreloadedStockVideo: e.target.value })
              }}
            >
              {Object.keys(preloadedStockAssets).map((key, index) => (
                // eslint-disable-next-line
                <option key={index} value={key}>
                  {preloadedStockAssets[key].name}
                </option>
              ))}
            </Select>
          )}
          <IconButton
            size="sm"
            icon={<TbX />}
            mr="10px"
            onClick={() => {
              updateState({
                controllsStatus: 'idle',
                selectedPreloadedStockVideo: undefined,
              })
            }}
          />
          <IconButton
            size="sm"
            icon={<TbCheck />}
            onClick={onSelectPreloadedStockVideo}
          />
        </>
      )
    } else if (controllsStatus === 'edit')
      controllsJSX = (
        <>
          <IconButton
            size="sm"
            icon={<TbX />}
            mr="10px"
            onClick={() => {
              updateState({ controllsStatus: 'idle', editedText: text })
            }}
          />
          <IconButton size="sm" icon={<TbCheck />} onClick={onEditScript} />
        </>
      )
    else if (controllsStatus === 'idle') {
      if (typeof showControllButtons === 'boolean') {
        // Show all controlls
        const controllKeys = Object.keys(controlls)
        controllsJSX = sortBy(controllKeys, (k) => controlls[k].order).map(
          (key) => controlls[key].content
        )
      } else {
        // Show selected controlls
        const selectedControllKeys = Object.keys(showControllButtons)
        const controllKeys = Object.keys(controlls)

        controllsJSX = sortBy(controllKeys, (k) => controlls[k].order)
          .filter((key) => selectedControllKeys.includes(key))
          .map((k) => controlls[k].content)
      }
    }
  }

  const textColumnWidth = generatedAudio ? '100%' : '100%'

  const ControllsContainer = styled(Flex)`
    button:not(:last-child) {
      margin-right: 8px;
    }
  `

  let uploadingFilesJsx = null
  if (files && files.length > 0) {
    uploadingFilesJsx = files.map((file) => (
      <FileUploadUI
        file={file}
        videoFlowID={videoFlowID}
        order={file.order}
        onComplete={onUploadComplete}
        onCancel={removeCompletedTask}
        key={file.id}
      />
    ))
  }

  let uploadedFilesJsx = null
  if (stock && stock.length > 0) {
    uploadedFilesJsx = (
      <StockAssetsList stock={stock} videoFlowID={videoFlowID} />
    )
  }

  let carouselJsx = null
  if (assets && assets.length > 0) {
    const sortedAssets = sortBy(assets, (a) => a.order)
    carouselJsx = <AssetsSlider assets={sortedAssets} />
  }

  return (
    <Box p="24px" bg={bgColor} my="22px" borderRadius="12px">
      {showControllButtons && text && text.length > 0 && (
        <ControllsContainer justifyContent="flex-end" mb="18px">
          {controllsJSX}
        </ControllsContainer>
      )}
      {uploadingFilesJsx}
      {uploadedFilesJsx}
      {carouselJsx}
      <Flex justify="space-between" w="100%">
        <Flex direction="column" width={textColumnWidth} color={textColor}>
          <ContentEditable
            style={{
              marginBottom: '10px',
              fontSize: '16px',
              border: 'none',
              outline: 'none',
            }}
            html={editedText}
            disabled={controllsStatus !== 'edit'}
            onChange={(e) => {
              updateState({ editedText: e.target.value })
            }}
          />
        </Flex>
      </Flex>
      {generatedAudio && (
        <Flex justify="space-between" mt="24px" w="100%">
          <Flex direction="column" width={textColumnWidth}>
            <AudioPlayer src={generatedAudio} autoPlay={false} />
          </Flex>
        </Flex>
      )}
    </Box>
  )
}

export default VideoDetailScriptRow
