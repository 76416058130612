import React, { useEffect, useState } from 'react'
import values from 'lodash/values'
import sortBy from 'lodash/sortBy'
import { Flex, Text, useColorModeValue } from '@chakra-ui/react'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'

import { db } from '_firebase'
import { doc, getDoc } from 'firebase/firestore'
import CardBody from 'components/Card/CardBody'
import VideoDetailAffiliateRow from 'components/Tables/VideoDetailAffiliateRow'

function Affiliate({ videoDetails, verticalID }) {
  const textColor = useColorModeValue('gray.700', 'white')
  const [affiliateParams, setAffiliateParams] = useState({})
  // Sort the results by result index
  const results = values(videoDetails.results)
  const sortedResults = sortBy(results, (result) =>
    Number(result.resultIndex.replace('result', ''))
  )

  const getAffiliateParams = async (verticalID) => {
    const source = verticalID === 'vacationHomes' ? 'Booking' : ''
    if (!source) return
    const affiliateRef = doc(db, 'affiliates', source)
    // eslint-disable-next-line no-await-in-loop
    const affiliateSnap = await getDoc(affiliateRef)
    setAffiliateParams(affiliateSnap.data())
    // return affiliateSnap.data()
  }

  // Render results UI
  if (!sortedResults) return null

  useEffect(() => {
    getAffiliateParams(verticalID)
  }, [])

  return (
    <Card my={{ lg: '24px' }} me={{ lg: '24px' }}>
      <Flex direction="column">
        <CardHeader py="12px">
          <Text color={textColor} fontSize="lg" fontWeight="bold">
            Affiliate Links
          </Text>
        </CardHeader>
        <CardBody>
          {affiliateParams &&
            sortedResults.map((result) => (
              <VideoDetailAffiliateRow
                key={result.id}
                result={result}
                affiliateParams={affiliateParams}
              />
            ))}
        </CardBody>
      </Flex>
    </Card>
  )
}

export default Affiliate
