/* eslint-disable no-unused-vars */
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { v4 as uuid } from 'uuid'
import { assetsStorage } from '_firebase'
import { IMAGE_WIDTH, IMAGE_HEIGHT } from './constants'

function drawThumbnailToCanvas(imageUrl, title, channelName, cb) {
  const canvas = document.createElement('canvas')
  canvas.width = IMAGE_WIDTH
  canvas.height = IMAGE_HEIGHT

  const ctx = canvas.getContext('2d')

  // Render image
  const image = new Image()
  image.crossOrigin = 'anonymous'

  // Gradient overlay over an image
  const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height)
  gradient.addColorStop(0, 'rgba(158, 15, 47, 0.473)')
  gradient.addColorStop(1, 'rgba(0, 0, 0, 0.45)')

  // Draw image
  image.onload = () => {
    // Calculate the aspect ratio of the image and the canvas
    const imageAspectRatio = image.width / image.height
    const canvasAspectRatio = canvas.width / canvas.height

    // Calculate the scaling factor for the image
    let scale = 1
    if (canvasAspectRatio > imageAspectRatio) {
      scale = canvas.width / image.width
    } else {
      scale = canvas.height / image.height
    }

    // Calculate the centering offsets for the image
    const offsetX = (canvas.width - image.width * scale) / 2
    const offsetY = (canvas.height - image.height * scale) / 2

    // Draw the image on the canvas
    ctx.drawImage(
      image,
      offsetX,
      offsetY,
      image.width * scale,
      image.height * scale
    )

    // Draw gradient
    ctx.fillStyle = gradient
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    // Draw title
    ctx.font = "148px 'Bebas Neue'"
    ctx.fillStyle = '#fff'
    ctx.textAlign = 'center'
    ctx.fillText(title.toUpperCase() || 'Title', canvas.width / 2, 330)

    // Draw seperator
    ctx.fillRect(canvas.width / 2 - 500, 388, 1000, 5)

    // Draw channel name
    ctx.fillStyle = '#fff'
    ctx.font = '48px Montserrat'
    ctx.fillText(
      channelName.toUpperCase() || 'Channel Name',
      canvas.width / 2,
      330 + 148
    )

    // Pass the canvas to callback function
    cb(canvas)
  }

  image.src = imageUrl
}

export function downloadThumbnail(imageUrl, title, channelName) {
  drawThumbnailToCanvas(imageUrl, title, channelName, (canvas) => {
    const downloadLink = document.createElement('a')
    downloadLink.setAttribute('download', 'thumbnail')
    downloadLink.setAttribute('href', canvas.toDataURL('image/png', 1.0))
    downloadLink.click()
  })
}

export function downloadThumbnailFromURL(url) {
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/png',
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `thumbnail.png`)

      document.body.appendChild(link)

      link.click()

      // Clean up and remove the link
      link.parentNode.removeChild(link)
    })
}

// Helper function to convert a data URL to a Blob
function dataURLToBlob(dataURL) {
  const arr = dataURL.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

export function saveToStorage(
  imageUrl,
  title,
  channelName,
  videoFlowID,
  progressCB,
  completeCB,
  errorCB
) {
  drawThumbnailToCanvas(imageUrl, title, channelName, (canvas) => {
    // Convert the canvas to a data URL
    const dataURL = canvas.toDataURL()

    // Convert the data URL to a Blob
    const blob = dataURLToBlob(dataURL)

    let ext = 'png'
    if (blob.type) {
      const mimeTypeSplit = blob.type.split('/')
      ext = mimeTypeSplit[mimeTypeSplit.length - 1]
    }
    const fileId = uuid()
    const filePath = `${videoFlowID}/thumbnail/${fileId}.${ext}`

    const fileRef = ref(assetsStorage, filePath)

    const task = uploadBytesResumable(fileRef, blob)

    task.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.floor(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )
        progressCB(progress)
      },
      () => {
        errorCB()
      },
      () => {
        getDownloadURL(fileRef).then((url) => {
          completeCB(url)
        })
      }
    )
  })
}
