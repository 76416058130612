import values from 'lodash/values'
import sortBy from 'lodash/sortBy'
import { isUndefined, pickBy, isEmpty } from 'lodash'

/**
 * status1: Used when process is not started
 * status2: Used when process is not completed
 */

export const videoGenerationSteps = [
  {
    step: 0,
    status1: 'PENDING',
    status2: 'RUNNING',
    process: 'START JOB',
    endpointId: 'startJob',
  },

  {
    step: 1,
    status1: 'READY FOR ALGORITHM',
    status2: 'SCORING',
    process: 'PROCESS ALGORITHM',
    endpointId: 'processAlgorithm',
  },

  {
    step: 2,
    status1: 'READY FOR PACKAGING',
    status2: 'PACKAGING',
    process: 'PACKAGE VIDEO',
    endpointId: 'packageVideo',
    needsInput: true,
  },

  {
    step: 3,
    status1: 'READY FOR ASSETS',
    status2: 'UPLOADING ASSETS',
    process: 'UPLOAD ASSETS',
    isOptional: true,
  },

  {
    step: 4,
    status1: 'READY FOR SCRIPT',
    status2: 'GENERATING SCRIPT',
    process: 'GENERATE SCRIPT',
    endpointId: 'generateScript',
  },

  {
    step: 5,
    status1: 'READY FOR AUDIO',
    status2: 'GENERATING AUDIO',
    process: 'GENERATE AUDIO',
    endpointId: 'generateAudio',
  },

  {
    step: 6,
    status1: 'READY FOR THUMBNAIL',
    status2: 'GENERATING THUMBNAIL',
    process: 'GENERATE THUMBNAIL',
    endpointId: 'processThumbnail',
    needsInput: true,
  },

  {
    step: 7,
    status1: 'READY FOR VIDEO',
    status2: 'GENERATING VIDEO',
    process: 'GENERATE VIDEO',
    endpointId: 'generateVideo',
  },

  {
    step: 8,
    status1: 'COMPLETED',
    status2: 'GENERATING VARIANT',
    process: 'GENERATE VARIANT',
    endpointId: 'generateVariant',
  },
]

// Utitlity functions
export const convertArrayToMap = (arr, format) =>
  arr.reduce((obj, current, index) => format(obj, current, index), {})

export const reorderList = (list, startIndex, endIndex, mapNewItem) => {
  if (isEmpty(list)) return []
  const newList = [...list]
  const removed = newList.splice(startIndex, 1)[0]
  newList.splice(endIndex, 0, removed)

  return newList.map((item, index) => mapNewItem(item, index))
}

export const removeItemFromList = (list, index) => {
  if (isEmpty(list)) return []

  return [...list.slice(0, index), ...list.slice(index + 1)]
}

export const sortVideoItems = (videoDetails) => {
  const assets = videoDetails.assets || {}
  const generatedAudio = videoDetails.generatedAudio || {}
  const results = videoDetails.results || {}
  const script = videoDetails.script || {}

  // Sort the assets by resultIndex
  const assetKeys = Object.keys(assets).filter((key) => key !== 'stock')
  const sortedAssetsKeys = sortBy(assetKeys, (key) =>
    Number(key.replace('result', ''))
  )
  const sortedAssets = sortedAssetsKeys.map((key) => assets[key])

  // Sort the audio by resultIndex
  const audioKeys = Object.keys(generatedAudio).filter(
    (key) => key !== 'topic' && key !== 'outro'
  )
  const sortedAudioKeys = sortBy(audioKeys, (key) =>
    Number(key.replace('result', ''))
  )
  const sortedAudio = sortedAudioKeys.map((key) => generatedAudio[key])

  // Sort the results by result index
  const resultsValues = values(results)
  const sortedResults = sortBy(resultsValues, (result) =>
    Number(result.resultIndex.replace('result', ''))
  )

  // Sort the script by resultIndex
  const scriptKeys = Object.keys(script).filter(
    (key) => key !== 'topic' && key !== 'outro'
  )
  const sortedScriptKeys = sortBy(scriptKeys, (key) =>
    Number(key.replace('result', ''))
  )
  const sortedScript = sortedScriptKeys.map((key) => script[key])

  return {
    assets: sortedAssets,
    generatedAudio: sortedAudio,
    results: sortedResults,
    script: sortedScript,
  }
}

export const convertVideoItemsListToMap = (items, videoDetails) => {
  const { assets, generatedAudio, results, script } = items

  // Convert assets to map
  const assetsMap = convertArrayToMap(assets, (obj, current, index) => {
    const newObj = { ...obj }
    newObj[`result${index + 1}`] = current
    return newObj
  })
  if (videoDetails.assets && videoDetails.assets.stock) {
    assetsMap.stock = videoDetails.assets.stock
  }

  // Convert audio to map
  const audioMap = convertArrayToMap(generatedAudio, (obj, current, index) => {
    const newObj = { ...obj }
    newObj[`result${index + 1}`] = current
    return newObj
  })
  if (videoDetails.generatedAudio && videoDetails.generatedAudio.topic) {
    audioMap.topic = videoDetails.generatedAudio.topic
  }
  if (videoDetails.generatedAudio && videoDetails.generatedAudio.outro) {
    audioMap.outro = videoDetails.generatedAudio.outro
  }

  // Convert results to map
  const resultsMap = convertArrayToMap(results, (obj, current) => {
    const newObj = { ...obj }
    newObj[current.id] = current
    return newObj
  })

  // Convert script to map
  const scriptMap = convertArrayToMap(script, (obj, current, index) => {
    const newObj = { ...obj }
    newObj[`result${index + 1}`] = current
    return newObj
  })
  if (videoDetails.script && videoDetails.script.topic) {
    scriptMap.topic = videoDetails.script.topic
  }
  if (videoDetails.script && videoDetails.script.outro) {
    scriptMap.outro = videoDetails.script.outro
  }

  return {
    assets: assetsMap,
    generatedAudio: audioMap,
    results: resultsMap,
    script: scriptMap,
  }
}

export const removeUndefinedFromObject = (obj) =>
  pickBy(obj, (value) => !isUndefined(value))
