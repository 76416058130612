import React from 'react'
import styled from '@emotion/styled'
import { Text } from '@chakra-ui/react'

const Preview = styled('div')`
  width: 100%;
  aspect-ratio: 16 / 9;
  background: ${(
    props
  ) => `linear-gradient(rgba(158, 15, 47, 0.473), rgba(0, 0, 0, 0.45)),
    url('${props.fileUrl}')`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
`

const PreviewContent = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 25%;
`

const Seprator = styled('div')`
  width: 85%;
  height: 2px;
  background-color: #fff;
  margin: '20px auto 30px auto';
  margin: 0.6em auto;
  @media (min-width: 768px) {
    height: 5px;
    margin: 1.6em auto 2em auto;
  }
`

function ThumbnailPreview({ file, title, channelName }) {
  return (
    <Preview fileUrl={file}>
      <PreviewContent>
        <Text
          fontSize={{ base: '3xl', md: '7xl', lg: '9xl' }}
          style={{
            fontFamily: 'Bebas Neue',
            lineHeight: 1,
            color: 'white',
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Text>
        <Seprator />
        <Text
          fontSize={{ base: 'md', md: '3xl', lg: '4xl' }}
          style={{
            fontFamily: 'Montserrat',
            lineHeight: 1,
            fontWeight: 700,
            color: 'white',
            textTransform: 'uppercase',
          }}
        >
          {channelName}
        </Text>
      </PreviewContent>
    </Preview>
  )
}

export default ThumbnailPreview
