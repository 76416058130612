import React, { useState } from 'react'
import {
  Box,
  Image,
  IconButton,
  useColorModeValue,
  useToast,
  Stack,
} from '@chakra-ui/react'
import { DragHandleIcon } from '@chakra-ui/icons'
import { FaTrash } from 'react-icons/fa'
import { arrayRemove, doc, updateDoc } from 'firebase/firestore'
import { db } from '_firebase'
import { Draggable } from 'react-beautiful-dnd'

function MediaPreview({ file, videoFlowID, index }) {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const trashButtonBgColor = useColorModeValue(
    'rgba(200, 200, 200, 0.6)',
    'rgba(0, 0, 0, 0.6)'
  )

  const handleDelete = async () => {
    setIsLoading(true)
    const videoRef = doc(db, 'videos', videoFlowID)

    try {
      await updateDoc(videoRef, {
        'assets.stock': arrayRemove(file),
      })

      toast({
        title: 'Deleting stock',
        description: 'Operation has been completed successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } catch (err) {
      setIsLoading(false)
      toast({
        title: 'Deleting stock',
        description: 'Operation has been failed try later',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  let mediaJsx = null
  if (file.type === 'image') {
    mediaJsx = (
      <Image
        width="100%"
        maxW="100%"
        height="auto"
        objectFit="cover"
        src={file.url}
      />
    )
  } else if (file.type === 'video') {
    mediaJsx = (
      <video
        controls
        style={{
          width: '100%',
          maxWidth: '100%',
          height: 'auto',
        }}
      >
        <track kind="captions" />
        <source src={file.url} />
      </video>
    )
  }
  return (
    <Draggable draggableId={file.id} index={index}>
      {(provided) => (
        <div {...provided.draggableProps} ref={provided.innerRef}>
          <Box mb={3} position="relative">
            <Stack
              position="absolute"
              top="10px"
              right="10px"
              zIndex={100}
              direction="row"
              alignItems="center"
            >
              <IconButton
                size="md"
                icon={<FaTrash />}
                color="var(--chakra-colors-red-500);"
                onClick={handleDelete}
                isLoading={isLoading}
                disabled={isLoading}
                backgroundColor={trashButtonBgColor}
              />
              <Box
                fontSize="md"
                bgColor={trashButtonBgColor}
                padding="8px"
                borderRadius="8px"
                {...provided.dragHandleProps}
              >
                <DragHandleIcon />
              </Box>
            </Stack>
            {mediaJsx}
          </Box>
        </div>
      )}
    </Draggable>
  )
}

export default MediaPreview
