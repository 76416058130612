import { auth } from '_firebase'

export const makeAuthenticatedRequest = (url, method, body, headers = {}) => {
  let accessToken = ''

  const user = auth.currentUser

  if (user) accessToken = user.accessToken

  const newHeaders = {
    ...headers,
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: `Bearer ${accessToken}`,
  }

  return fetch(url, {
    method,
    body: JSON.stringify(body),
    headers: newHeaders,
  })
}

export const endpoints = {
  startVideoFlow: 'startVideoFlow',
  startJob: 'startJob',
  processJob: 'processJob',
  processAlgorithm: 'processAlgorithm',
  packageVideo: 'packageVideo',
  generateScript: 'generateScript',
  generateScriptForText: 'generateScriptForText',
  processScriptText: 'processScriptText',
  generateAudio: 'generateAudio',
  updateScriptForText: 'updateScriptForText',
  generateVideo: 'generateVideo',
  processVideo: 'processVideo',
  processThumbnail: 'processThumbnail',
}

export const getEndpointUrl = (endpoint) => {
  if (!endpoint) throw new Error('Call it will an endpoint name')

  const env = process.env.NODE_ENV

  if (env === 'development')
    return `http://127.0.0.1:5001/pdmedia-video/us-central1/${endpoint}`

  return `/api/${endpoint}`
}
