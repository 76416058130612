import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

function VideoDetailAffiliateRow(props) {
  const nameColor = useColorModeValue('gray.900', 'white')
  const bgColor = useColorModeValue('#F8F9FA', 'navy.900')
  const { result, affiliateParams } = props

  const calculateNumberOfBedrooms = (description) => {
    let numberOfBedrooms
    for (let i = 1; i <= 10; i++) {
      const variations = [`${i} bedroom`, `${i} BR`, `${i} separate bedroom`]
      if (variations.some((value) => description.includes(value))) {
        if (i === 1) {
          numberOfBedrooms = '1 Bedroom'
        } else {
          numberOfBedrooms = `${i} bedrooms`
        }
      }
    }
    return numberOfBedrooms
  }

  const getURLWithQueryParams = (url) => {
    const queryParams = Object.entries(affiliateParams)
      .map(([k, v]) => `${k}=${v}`)
      .join('&')
    return `${url}?${queryParams}`
  }

  return (
    <Box p="24px" bg={bgColor} my="22px" borderRadius="12px">
      <Flex direction="row" justify="space-between" w="100%">
        <Text color={nameColor} fontSize="md" mb="10px">
          ○ {result.name} | ★ {result.calculatedScore.toFixed(1)} |{' '}
          {calculateNumberOfBedrooms(result.description)} |{' '}
          {getURLWithQueryParams(result.url, affiliateParams)}
        </Text>
      </Flex>
    </Box>
  )
}

export default VideoDetailAffiliateRow
