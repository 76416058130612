/*!

=========================================================
* Argon Dashboard Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-chakra/blob/master/LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from 'App'
import { AuthProvider } from 'providers/authProvider'
import { ChakraProvider } from '@chakra-ui/react'
import theme from 'theme/theme'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <AuthProvider>
    <ChakraProvider theme={theme} resetCss={false} position="relative">
      <App />
    </ChakraProvider>
  </AuthProvider>
)
