import {
  Flex,
  Grid,
  Text,
  Button,
  useColorModeValue,
  Icon,
  useDisclosure,
  Spinner,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import React, { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { db } from '_firebase'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { CreateNewVideoModal } from 'modals/CreateNewVideoModal'
import { videoGenerationSteps } from 'components/videoDetails/utils'
import { Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { Video } from './video'

export function Videos() {
  const [state, setState] = useState({
    status: 'loading',
    videos: [],
  })

  const { search } = useLocation()
  const queryValues = queryString.parse(search)
  const { filter } = queryValues

  const { isOpen, onOpen, onClose } = useDisclosure()

  const textColor = useColorModeValue('gray.700', 'white')

  const { videos, status } = state

  const updateState = (newState) => {
    setState((oldState) => ({
      ...oldState,
      ...newState,
    }))
  }

  useEffect(() => {
    updateState({
      status: 'loading',
    })

    let q = collection(db, 'videoFlow')
    if (filter) {
      const decodedFilter = decodeURI(filter)
      q = query(
        collection(db, 'videoFlow'),
        where('status', '==', decodedFilter)
      )
    }

    const subscriber = onSnapshot(q, (querySnapshot) => {
      const videoResults = []
      querySnapshot.docs.forEach((doc) => {
        videoResults.push({
          ...doc.data(),
          id: doc.id,
        })
      })
      videoResults.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
      updateState({
        videos: videoResults,
        status: 'idle',
      })
    })
    return () => {
      subscriber()
    }
  }, [search])

  let videosJsx = null

  if (status === 'loading') {
    videosJsx = (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    )
  } else {
    videosJsx = (
      <Grid
        templateColumns={{ sm: '1fr', md: '1fr 1fr', xl: 'repeat(3, 1fr)' }}
        gap="24px"
      >
        <Button
          p="0px"
          bg="transparent"
          border="1px solid lightgray"
          borderRadius="15px"
          minHeight="430px"
          height="100%"
          onClick={onOpen}
        >
          <Flex direction="column" justifyContent="center" align="center">
            <Icon as={FaPlus} color={textColor} fontSize="lg" mb="12px" />
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Create a New Project
            </Text>
          </Flex>
        </Button>

        {videos.map((video) => (
          <Video video={video} key={video.id} />
        ))}
      </Grid>
    )
  }

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Box py="8" display="flex" justifyContent="flex-end" alignItems="center">
        <Text mr="4" fontSize="xl">
          Filter
        </Text>
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            {filter ? decodeURI(filter) : 'All'}
          </MenuButton>
          <MenuList>
            <MenuItem as={Link} to={encodeURI(`/admin/videos`)}>
              All
            </MenuItem>
            <MenuGroup title="Status">
              {videoGenerationSteps.map((step) => (
                <MenuItem
                  as={Link}
                  to={encodeURI(`/admin/videos?filter=${step.status1}`)}
                >
                  {step.status1}
                </MenuItem>
              ))}
            </MenuGroup>
            <MenuGroup title="Running">
              {videoGenerationSteps.map((step) => (
                <MenuItem
                  as={Link}
                  to={encodeURI(`/admin/videos?filter=${step.status2}`)}
                >
                  {step.status2}
                </MenuItem>
              ))}
            </MenuGroup>
          </MenuList>
        </Menu>
      </Box>
      <Card>
        <CardBody>{videosJsx}</CardBody>
      </Card>
      <CreateNewVideoModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}
