import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useToast,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { getEndpointUrl, makeAuthenticatedRequest, endpoints } from 'utils'

export function PackageVideoModal(props) {
  const { isOpen, onClose, videoFlowID } = props
  const [script, setScript] = useState({ topic: '', outro: '' })
  const [numberOfResults, setNumberOfResults] = useState(10)
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const packageVideo = () => {
    setIsLoading(true)

    makeAuthenticatedRequest(getEndpointUrl(endpoints.packageVideo), 'POST', {
      videoFlowID,
      numberOfResults,
      script,
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false)
        if (data) {
          toast({
            title: data.message,
            description: 'Please continue with the next step.',
            status: data.status === 200 ? 'success' : 'error',
            duration: 5000,
            isClosable: true,
          })
          onClose()
        }
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Package video</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb="24px" id="title">
            <FormLabel>Number of results</FormLabel>
            <NumberInput
              onChange={setNumberOfResults}
              defaultValue={10}
              min={1}
              max={20}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>

            <FormLabel mt="16px">Topic</FormLabel>
            <Input
              type="text"
              onChange={(event) => {
                setScript({ ...script, topic: event.target.value })
              }}
            />
            <FormHelperText>
              Please enter the topic for the video you are creating.
            </FormHelperText>

            <FormLabel mt="16px">Outro</FormLabel>
            <Input
              type="text"
              onChange={(event) => {
                setScript({ ...script, outro: event.target.value })
              }}
            />
            <FormHelperText>
              Please enter the outro for the video you are creating.
            </FormHelperText>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={
              !(
                numberOfResults &&
                script &&
                script.topic &&
                script.topic !== '' &&
                script.outro &&
                script.outro !== '' &&
                !isLoading
              )
            }
            onClick={packageVideo}
            colorScheme="blue"
            mr={3}
            isLoading={isLoading}
            loadingText="Creating..."
          >
            Create
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
