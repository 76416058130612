import React, { useRef, useState } from 'react'
import {
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import { IMAGE_WIDTH, IMAGE_HEIGHT } from './constants'

export function UploadFromURLModal({ isOpen, onClose, onCheck }) {
  const [value, setValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const inputRef = useRef()

  const toast = useToast()

  const handleCheck = () => {
    if (!value || value.trim().length < 0) return

    setIsLoading(true)

    const img = new Image()

    img.onload = () => {
      const imageWidth = img.width
      const imageHeight = img.height

      if (imageWidth < IMAGE_WIDTH || imageHeight < IMAGE_HEIGHT) {
        toast({
          title: 'Upload Guideline',
          description: `Image size must be at least ${IMAGE_WIDTH}x${IMAGE_HEIGHT}`,
          isClosable: true,
          duration: 5000,
          status: 'info',
        })

        setIsLoading(false)
      } else {
        setIsLoading(false)
        onCheck(value)
        onClose()
      }
    }

    img.src = value
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={inputRef}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Image from URL</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl pb={2}>
            <FormLabel htmlFor="imageUrl">Image URL</FormLabel>
            <InputGroup>
              <Input
                placeholder="Enter url here"
                ref={inputRef}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value)
                }}
                id="imageUrl"
              />
              <InputRightElement>
                <IconButton
                  icon={<CheckIcon />}
                  variant="ghost"
                  onClick={handleCheck}
                  isLoading={isLoading}
                />
              </InputRightElement>
            </InputGroup>
            <FormHelperText>
              Image size must be at least 1280x720
            </FormHelperText>
          </FormControl>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
