import React from 'react'
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'

export function SearchBar(props) {
  // Pass the computed styles into the `__css` prop
  const { ...rest } = props
  // Chakra Color Mode
  const searchIconColor = useColorModeValue('gray.700', 'gray.200')
  const inputBg = useColorModeValue('white', 'navy.800')
  return (
    <InputGroup borderRadius="8px" w="200px" {...rest}>
      <InputLeftElement>
        <IconButton
          bg="inherit"
          borderRadius="inherit"
          _hover="none"
          _active={{
            bg: 'inherit',
            transform: 'none',
            borderColor: 'transparent',
          }}
          _focus={{
            boxShadow: 'none',
          }}
          icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
        />
      </InputLeftElement>
      <Input
        variant="search"
        fontSize="xs"
        bg={inputBg}
        placeholder="Type here..."
      />
    </InputGroup>
  )
}
