import React from 'react'
import { Box, Flex, Image, Text, useColorModeValue } from '@chakra-ui/react'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import Card from 'components/Card/Card'

export function ThumbnailPreview({ url }) {
  const textColor = useColorModeValue('gray.700', 'white')
  return (
    <Card Card my="24px" ms={{ lg: '24px' }} style={{ overflow: 'hidden' }}>
      <Flex direction="column">
        <CardHeader py="12px">
          <Flex direction="column">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Thumbnail
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex direction="column" w="100%">
            <Box mb="20px" position="relative" borderRadius="15px">
              <Image src={url} borderRadius="15px" fallbackSrc={url} />
            </Box>
          </Flex>
        </CardBody>
      </Flex>
    </Card>
  )
}
