import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

function ViewResultsModalRow(props) {
  const bgColor = useColorModeValue('#F8F9FA', 'navy.900')
  const nameColor = useColorModeValue('gray.900', 'white')
  const { result, score } = props

  return (
    <Box p="24px" bg={bgColor} my="22px" borderRadius="12px">
      <Flex justify="space-between" w="100%">
        <Flex direction="column" maxWidth="100%">
          <Text color={nameColor} fontSize="md" fontWeight="bold" mb="10px">
            {result.name ? result.name : result.id}
          </Text>

          {result &&
            Object.keys(result).map((key) => (
              <Text
                color="gray.500"
                fontSize="sm"
                fontWeight="semibold"
                mb="8px"
                key={key}
              >
                {key}
                {': '}
                <Text as="span" color={nameColor} fontWeight="400">
                  {result[key]}
                </Text>
              </Text>
            ))}
          {score && (
            <Flex>
              <Text
                color="gray.500"
                fontSize="sm"
                fontWeight="semibold"
                mb="8px"
              >
                Score:
                <Text as="span" color={nameColor} fontWeight="400">
                  {score === '0' ? '1' : score}
                </Text>
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default ViewResultsModalRow
