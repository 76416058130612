import { initializeApp } from 'firebase/app'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectStorageEmulator, getStorage, ref } from 'firebase/storage'
import config from './config.json'

const env = process.env.NODE_ENV

const app = initializeApp(config)

let fdb
let fauth
let fStorage

// Connect to emulator in development mode
// TODO: fix this condition for development
if (env === 'test') {
  /**
   * Firestore
   */
  fdb = getFirestore()
  connectFirestoreEmulator(fdb, 'localhost', 8080)

  /**
   * Functions
   */
  const functions = getFunctions(app)
  connectFunctionsEmulator(functions, 'localhost', 5001)

  /**
   * Authentication
   */
  fauth = getAuth()
  connectAuthEmulator(fauth, 'http://localhost:9099')

  /**
   * Storage
   */
  fStorage = getStorage()
  connectStorageEmulator(fStorage, 'localhost', 4040)
} else {
  /**
   * Firestore
   */
  fdb = getFirestore(app)

  /**
   * Authentication
   */
  fauth = getAuth(app)

  /**
   * Storage
   */
  fStorage = getStorage(app)
}

const db = fdb
const auth = fauth
const assetsStorage = ref(fStorage, 'assets')

export { db, auth, assetsStorage }
