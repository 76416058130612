import React, { useState } from 'react'
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from '@chakra-ui/react'

import fallbackImage from 'assets/img/thumbnailPlaceholder.jpeg'
import ThumbnailPreview from './ThumbnailPreview'
import Controlls from './Controlls'

export function GenerateThumbnailModalContent({ videoFlowID, onClose }) {
  const [state, setState] = useState({
    title: '',
    channelName: '',
  })
  const [file, setFile] = useState(fallbackImage)

  const { title, channelName } = state

  const handleChange = (e) => {
    e.persist()
    setState((oldState) => ({
      ...oldState,
      [e.target.name]: e.target.value,
    }))
  }

  const titleToShow = title || 'Title'
  const channelNameToShow = channelName || 'Channel Name'

  return (
    <ModalContent>
      <ModalHeader>Generate thumbnail</ModalHeader>
      <ModalCloseButton />
      <ModalBody id="modal-body">
        {/* Thumbnail Preview */}
        <ThumbnailPreview
          file={file}
          title={titleToShow}
          channelName={channelNameToShow}
        />

        {/* Controlls */}
        <Controlls
          title={title}
          channelName={channelName}
          file={file}
          onFileChange={setFile}
          handleChange={handleChange}
          videoFlowID={videoFlowID}
          onModalClose={onClose}
        />
      </ModalBody>
    </ModalContent>
  )
}
