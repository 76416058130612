import React from 'react'
import {
  Flex,
  Box,
  Image,
  Text,
  useColorModeValue,
  Button,
  Badge,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import fallbackImage from 'assets/img/placeholder.png'

export function Video({ video }) {
  const textColor = useColorModeValue('gray.700', 'white')

  let sortedParametersKeys = []
  if (video.parameters) {
    sortedParametersKeys = sortBy(Object.keys(video.parameters))
  }

  return (
    <Flex direction="column">
      <Box mb="20px" position="relative" borderRadius="15px">
        <Image
          src={
            video.thumbnail ? video.thumbnail : 'https://picsum.photos/1110/575'
          }
          borderRadius="15px"
          fallbackSrc={fallbackImage}
        />
        <Box
          w="100%"
          h="100%"
          position="absolute"
          top="0"
          borderRadius="15px"
          bg="linear-gradient(360deg, rgba(49, 56, 96, 0.16) 0%, rgba(21, 25, 40, 0.88) 100%)"
        />
      </Box>
      <Flex direction="column" mb="20px">
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize="md" color="gray.400" fontWeight="600" mb="10px">
            {video.verticalID}
          </Text>
          <Badge variant="subtle" colorScheme="green" mb="10px">
            {video.status}
          </Badge>
        </Flex>
        <Text fontSize="xl" color={textColor} fontWeight="bold" mb="10px">
          {video.title}
        </Text>
        <Box mt="10px">
          <Flex align="center" mb="8px">
            <Text fontSize="md" color={textColor} me="10px">
              ID:
            </Text>
            <Text fontSize="md" color="gray.400">
              {video.id}
            </Text>
          </Flex>
          {sortedParametersKeys.map((key) => (
            <Flex align="center" mb="8px" key={key}>
              <Text fontSize="md" color={textColor} me="10px">
                {key}:
              </Text>
              <Text fontSize="md" color="gray.400">
                {`${video.parameters[key]}`}
              </Text>
            </Flex>
          ))}
        </Box>
      </Flex>
      <Flex justifyContent="space-between" mt="auto">
        <Link to={`/admin/videos/${video.id}`}>
          <Button variant="dark" minW="110px" h="36px">
            VIEW
          </Button>
        </Link>
      </Flex>
    </Flex>
  )
}
