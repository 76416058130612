import { extendTheme } from '@chakra-ui/react'
import { globalStyles } from './styles'
import { breakpoints } from './foundations/breakpoints'
import { buttonStyles } from './components/button'
import { stepsStyles } from './components/steps'
import { badgeStyles } from './components/badge'
import { linkStyles } from './components/link'
import { inputStyles } from './components/input'
import { CardComponent } from './additions/card/Card'
import { MainPanelComponent } from './additions/layout/MainPanel'
import { PanelContentComponent } from './additions/layout/PanelContent'
import { PanelContainerComponent } from './additions/layout/PanelContainer'
// import { mode } from "@chakra-ui/theme-tools";]

export default extendTheme(
  { breakpoints, config: { initialColorMode: 'system' } }, // Breakpoints
  globalStyles,
  buttonStyles, // Button styles
  badgeStyles, // Badge styles
  linkStyles, // Link styles
  inputStyles, // Input styles
  CardComponent, // Card component
  MainPanelComponent, // Main Panel component
  PanelContentComponent, // Panel Content component
  PanelContainerComponent, // Panel Container component
  stepsStyles
)
