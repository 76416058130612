import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'
import ViewResultsModalRow from 'components/Tables/ViewResultsModalRow'
import { db } from '_firebase'
import { doc, getDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'

export function ViewResultsModal(props) {
  const { videoFlowID, isOpen, onClose } = props
  const [results, setResults] = useState({})
  const [scores, setScores] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getResults = async () => {
      setIsLoading(true)
      const resultsRef = doc(db, 'results', videoFlowID)
      const resultsSnap = await getDoc(resultsRef)
      if (resultsSnap.exists()) {
        const resultsData = resultsSnap.data()
        // console.log(resultsData.data, results)
        setResults(resultsData.data)
        setScores(resultsData.scores || {})
      }
      // const verticalsResults = docSnap.docs.map((doc) => ({
      //     ...doc.data(),
      //     id: doc.id,
      // }));
      setIsLoading(false)
    }
    if (isOpen === true) {
      getResults()
    }
  }, [isOpen])

  let modalContentJsx = null

  if (isLoading) {
    modalContentJsx = (
      <Box display="flex" justifyContent="center" padding="30px 0px">
        <Spinner />
      </Box>
    )
  } else {
    modalContentJsx = (
      <>
        <ModalHeader>Results</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" w="100%">
            {results &&
              Object.values(results).map((result) => (
                <ViewResultsModalRow
                  result={result}
                  score={scores ? scores[result.id] : null}
                  key={result.id}
                />
              ))}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </>
    )
  }

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>{modalContentJsx}</ModalContent>
    </Modal>
  )
}
