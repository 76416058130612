import React, { useRef, useState } from 'react'
import {
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  useToast,
  CircularProgress,
  CircularProgressLabel,
} from '@chakra-ui/react'
import { AiOutlineCloudUpload, AiOutlineDesktop } from 'react-icons/ai'
import { TbWorldUpload } from 'react-icons/tb'
import { DownloadIcon } from '@chakra-ui/icons'
import { downloadThumbnail, saveToStorage } from './utils'
import { UploadFromURLModal } from './UploadFromURLModal'
import { IMAGE_HEIGHT, IMAGE_WIDTH } from './constants'
import {
  makeAuthenticatedRequest,
  getEndpointUrl,
  endpoints,
} from '../../utils'

function Controlls({
  title,
  channelName,
  file,
  onFileChange,
  handleChange,
  videoFlowID,
  onModalClose,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  const toast = useToast()
  const {
    isOpen: isUrlModalOpen,
    onOpen: onUrlModalOpen,
    onClose: onUrlModalClose,
  } = useDisclosure()

  const inputRef = useRef(null)

  return (
    <>
      <Stack spacing={5} mt={5} pb={2}>
        <Menu>
          <MenuButton
            as={Button}
            leftIcon={<AiOutlineCloudUpload />}
            alignSelf="flex-start"
            variant="outline"
          >
            Upload Image
          </MenuButton>
          <MenuList>
            <MenuItem icon={<TbWorldUpload />} onClick={onUrlModalOpen}>
              From url
            </MenuItem>
            <MenuItem
              icon={<AiOutlineDesktop />}
              onClick={() => inputRef.current.click()}
            >
              <input
                type="file"
                multiple={false}
                hidden
                ref={inputRef}
                accept="image/*"
                onChange={(e) => {
                  e.stopPropagation()
                  e.preventDefault()

                  const file = e.target.files[0]

                  const url = URL.createObjectURL(file)

                  const img = new Image()

                  img.onload = () => {
                    const { width, height } = img

                    if (width < IMAGE_WIDTH || height < IMAGE_HEIGHT) {
                      toast({
                        title: 'Upload Guideline',
                        description: `Image size must be at least ${IMAGE_WIDTH}x${IMAGE_HEIGHT}`,
                        isClosable: true,
                        duration: 5000,
                        status: 'info',
                      })

                      /** Empty the value so user can select the previously selected file
                       * from the device, if don't emty the value the user will not be
                       * able to select the file that was there before user enetred the file url
                       *  */
                      if (inputRef && inputRef.current) {
                        inputRef.current.value = null
                      }
                    } else {
                      onFileChange(url)
                    }
                  }

                  img.src = url
                }}
              />
              From device
            </MenuItem>
          </MenuList>
        </Menu>
        <FormControl>
          <FormLabel htmlFor="title">Title</FormLabel>
          <Input
            id="title"
            name="title"
            onChange={handleChange}
            value={title}
            placeholder="Enter title"
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="channelName">Channel Name</FormLabel>
          <Input
            id="channelName"
            name="channelName"
            onChange={handleChange}
            value={channelName}
            placeholder="Enter channel name"
          />
        </FormControl>

        <Stack direction="row" alignItems="center">
          {isLoading ? (
            <CircularProgress value={progress} color="green">
              <CircularProgressLabel>{progress}%</CircularProgressLabel>
            </CircularProgress>
          ) : null}
          <Button
            onClick={() => {
              setIsLoading(true)
              saveToStorage(
                file,
                title,
                channelName,
                videoFlowID,
                (pro) => {
                  setProgress(pro)
                },
                (thumbnail) => {
                  setIsLoading(false)
                  setProgress(0)
                  if (!thumbnail) {
                    toast({
                      title: 'Operation Failed',
                      description:
                        'Something went wrong while saving Thumbnail.',
                      isLoading: true,
                      duration: 5000,
                      status: 'error',
                    })
                  }
                  makeAuthenticatedRequest(
                    getEndpointUrl(endpoints.processThumbnail),
                    'POST',
                    {
                      videoFlowID,
                      thumbnail,
                    }
                  )
                    .then((res) => res.json())
                    .then(() => {
                      toast({
                        title: 'Operation Success',
                        description: 'Thumbnail has been saved successfully',
                        isLoading: true,
                        duration: 5000,
                        status: 'success',
                      })
                      onModalClose()
                    })
                    .catch(() => {
                      toast({
                        title: 'Operation Failed',
                        description:
                          'Something went wrong while saving Thumbnail.',
                        isLoading: true,
                        duration: 5000,
                        status: 'error',
                      })
                    })
                },
                () => {
                  setIsLoading(false)
                  setProgress(0)
                  toast({
                    title: 'Operation Failed',
                    description: 'Something went wrong while saving Thumbnail.',
                    isLoading: true,
                    duration: 5000,
                    status: 'error',
                  })
                }
              )
            }}
            disabled={isLoading}
            flexGrow={1}
            flexShrink={1}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
          <Button
            onClick={() => downloadThumbnail(file, title, channelName)}
            leftIcon={<DownloadIcon />}
          >
            Download
          </Button>
        </Stack>
      </Stack>

      {/* Modal for accepting image url */}
      <UploadFromURLModal
        isOpen={isUrlModalOpen}
        onClose={onUrlModalClose}
        onCheck={(url) => {
          onFileChange(url)
          /** Empty the value so user can select the previously selected file
           * from the device, if don't emty the value the user will not be
           * able to select the file that was there before user enetred the file url
           *  */
          if (inputRef && inputRef.current) {
            inputRef.current.value = null
          }
        }}
      />
    </>
  )
}

export default Controlls
