import { Box, Spinner, useColorModeValue } from '@chakra-ui/react'
import { useAuth } from 'providers/authProvider'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Routes } from 'routes/index'
import 'style.css'

export function App() {
  const { isLoading } = useAuth()

  const backgroundColor = useColorModeValue('white', 'navy.800')

  if (isLoading)
    return (
      <Box
        width="100vw"
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor={backgroundColor}
      >
        <Spinner width="30px" height="30px" />
      </Box>
    )

  return (
    <Router>
      <Routes />
    </Router>
  )
}
