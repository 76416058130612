import React from 'react'

export const renderTrack = ({ style, ...props }) => {
  const trackStyle = {
    position: 'absolute',
    maxWidth: '100%',
    width: 6,
    transition: 'opacity 200ms ease 0s',
    opacity: 0,
    bottom: 2,
    top: 2,
    borderRadius: 3,
    right: 0,
  }
  return <div style={{ ...style, ...trackStyle }} {...props} />
}
export const renderTrackRTL = ({ style, ...props }) => {
  const trackStyle = {
    position: 'absolute',
    width: 6,
    transition: 'opacity 200ms ease 0s',
    opacity: 0,
    bottom: 2,
    top: 2,
    borderRadius: 3,
    right: 'unset',
    left: 0,
  }
  return <div style={{ ...style, ...trackStyle }} {...props} />
}
export const renderThumbDark = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 15,
    background: 'rgba(222, 222, 222, .1)',
  }
  return <div style={{ ...style, ...thumbStyle }} {...props} />
}
export const renderThumbLight = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 15,
    background: 'rgba(48, 48, 48, .1)',
  }
  return <div style={{ ...style, ...thumbStyle }} {...props} />
}
export const renderView = ({ style, ...props }) => {
  const viewStyle = {
    marginRight: -22,
  }
  return <div style={{ ...style, ...viewStyle }} {...props} />
}
export const renderViewRTL = ({ style, ...props }) => {
  const viewStyle = {
    marginRight: 'unset',
    marginLeft: -15,
  }
  return <div style={{ ...style, ...viewStyle }} {...props} />
}

export const kanbanRenderTrack = ({ style, ...props }) => {
  const trackStyle = {
    width: 6,
    transition: 'opacity 200ms ease 0s',
    opacity: 0,
    bottom: 2,
    top: 2,
    borderRadius: 3,
    right: 0,
  }
  return <div style={{ ...style, ...trackStyle }} {...props} />
}
export const kanbanRenderThumbDark = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 15,
    background: 'rgba(222, 222, 222, .1)',
  }
  return <div style={{ ...style, ...thumbStyle }} {...props} />
}
export const kanbanRenderThumbLight = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 15,
    background: 'rgba(48, 48, 48, .1)',
  }
  return <div style={{ ...style, ...thumbStyle }} {...props} />
}
export const kanbanRenderView = ({ style, ...props }) => {
  const viewStyle = {
    position: 'relative',
    marginRight: -15,
  }
  return <div style={{ ...style, ...viewStyle }} {...props} />
}
