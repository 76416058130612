import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

function VideoDetailJobRow(props) {
  const bgColor = useColorModeValue('#F8F9FA', 'navy.900')
  const nameColor = useColorModeValue('gray.500', 'white')
  const {
    id,
    sourceID,
    status,
    createdAt,
    completedAt,
    pendingTasks,
    failedTasks,
  } = props

  return (
    <Box p="24px" bg={bgColor} borderRadius="12px">
      <Flex justify="space-between" w="100%">
        <Flex direction="column" maxWidth="100%">
          <Text color={nameColor} fontSize="md" fontWeight="bold" mb="10px">
            {id}
          </Text>
          <Text color="gray.400" fontSize="sm" fontWeight="semibold">
            Source:{' '}
            <Text as="span" color={nameColor}>
              {sourceID}
            </Text>
          </Text>
          <Text color="gray.400" fontSize="sm" fontWeight="semibold">
            Status:{' '}
            <Text as="span" color={nameColor}>
              {status}
            </Text>
          </Text>
          <Text color="gray.400" fontSize="sm" fontWeight="semibold">
            Created At:{' '}
            <Text as="span" color={nameColor}>
              {new Date(
                createdAt.seconds * 1000 + createdAt.nanoseconds / 1000000
              ).toLocaleString()}
            </Text>
          </Text>
          {completedAt && (
            <Text color="gray.400" fontSize="sm" fontWeight="semibold">
              Completed At:{' '}
              <Text as="span" color={nameColor}>
                {new Date(
                  completedAt.seconds * 1000 + completedAt.nanoseconds / 1000000
                ).toLocaleString()}
              </Text>
            </Text>
          )}
          {pendingTasks && Object.keys(pendingTasks).length > 0 && (
            <Text color="gray.400" fontSize="sm" fontWeight="semibold">
              Pending Jobs:{' '}
              <Text as="span" color={nameColor}>
                {Object.keys(pendingTasks).length}
              </Text>
            </Text>
          )}
          {failedTasks && Object.keys(failedTasks).length > 0 && (
            <Text color="gray.400" fontSize="sm" fontWeight="semibold">
              Failed Jobs:{' '}
              <Text as="span" color={nameColor}>
                {Object.keys(failedTasks).length}
              </Text>
            </Text>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default VideoDetailJobRow
