import React, { useState } from 'react'
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  Switch,
  useColorModeValue,
  Spinner,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react'
// Assets
import signInImage from 'assets/img/signInImage.png'
import { emailPasswordSignin } from '_firebase/auth-utils'
import { useAuth } from 'providers/authProvider'
import { Redirect, useLocation } from 'react-router-dom'
import { browserSessionPersistence, setPersistence } from 'firebase/auth'
import { auth } from '_firebase'

function SignIn() {
  const { user } = useAuth()
  const { state: routeState } = useLocation()

  const bgForm = useColorModeValue('white', 'navy.800')

  const [state, setState] = useState({
    email: '',
    password: '',
    status: 'idle',
    error: 'null',
    rememberMe: false,
  })

  // eslint-disable-next-line no-underscore-dangle
  const _setState = (newState) => {
    setState((oldState) => ({
      ...oldState,
      ...newState,
    }))
  }

  const { email, password, status, rememberMe } = state

  const handleChange = (e) => {
    _setState({
      [e.target.name]: e.target.value,
    })
  }

  /** This function takes email and passwrod and signin user */
  const signinUser = async () => {
    _setState({ status: 'loading' })

    if (!rememberMe) await setPersistence(auth, browserSessionPersistence)

    const response = await emailPasswordSignin(email, password)
    if (response.status !== 'success') {
      _setState({
        status: 'error',
        error: response.message,
      })
    }
  }

  // Redirect to page from where user came if there is a user
  if (user) return <Redirect to={routeState.from} />

  return (
    <>
      {status === 'error' && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>This is an error</AlertDescription>
        </Alert>
      )}
      <Flex position="relative" mb="40px">
        <Flex
          minH={{ md: '1000px' }}
          h={{ sm: 'initial', md: '75vh', lg: '85vh' }}
          w="100%"
          maxW="1044px"
          mx="auto"
          justifyContent="space-between"
          mb="30px"
          pt={{ md: '0px' }}
        >
          <Flex
            w="100%"
            h="100%"
            alignItems="center"
            justifyContent="center"
            mb="60px"
            mt={{ base: '50px', md: '20px' }}
          >
            <Flex
              zIndex="2"
              direction="column"
              w="445px"
              background="transparent"
              borderRadius="15px"
              p="40px"
              mx={{ base: '100px' }}
              m={{ base: '20px', md: 'auto' }}
              bg={bgForm}
              boxShadow={useColorModeValue(
                '0px 5px 14px rgba(0, 0, 0, 0.05)',
                'unset'
              )}
            >
              <FormControl>
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="normal"
                  htmlFor="email"
                >
                  Email
                </FormLabel>
                <Input
                  variant="auth"
                  fontSize="sm"
                  ms="4px"
                  type="text"
                  placeholder="Your email"
                  mb="24px"
                  size="lg"
                  value={email}
                  name="email"
                  onChange={handleChange}
                  id="email"
                />
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="normal"
                  htmlFor="password"
                >
                  Password
                </FormLabel>
                <Input
                  variant="auth"
                  fontSize="sm"
                  ms="4px"
                  type="password"
                  placeholder="Your password"
                  mb="24px"
                  size="lg"
                  value={password}
                  name="password"
                  onChange={handleChange}
                  id="password"
                />
                <FormControl display="flex" alignItems="center" mb="24px">
                  <Switch
                    id="remember-login"
                    colorScheme="blue"
                    me="10px"
                    isChecked={rememberMe}
                    onChange={() => {
                      _setState({
                        rememberMe: !rememberMe,
                      })
                    }}
                  />
                  <FormLabel
                    htmlFor="remember-login"
                    mb="0"
                    fontWeight="normal"
                  >
                    Remember me
                  </FormLabel>
                </FormControl>
                <Button
                  fontSize="10px"
                  variant="dark"
                  fontWeight="bold"
                  w="100%"
                  h="45"
                  mb="24px"
                  disabled={status === 'loading'}
                  onClick={signinUser}
                >
                  {status === 'loading' && (
                    <Spinner style={{ marginRight: 10 }} />
                  )}
                  SIGN IN
                </Button>
              </FormControl>
            </Flex>
          </Flex>
          <Box
            overflowX="hidden"
            h="100%"
            w="100%"
            left="0px"
            position="absolute"
            bgImage={signInImage}
          >
            <Box w="100%" h="100%" bgSize="cover" bg="blue.500" opacity="0.8" />
          </Box>
        </Flex>
      </Flex>
    </>
  )
}

export default SignIn
