import React from 'react'
import { Modal, ModalOverlay } from '@chakra-ui/react'
import { GenerateThumbnailModalContent } from './GenerateThumbnailModalContent'

function GenerateThumbnailModal({ isOpen, onClose, videoFlowID }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <GenerateThumbnailModalContent
        videoFlowID={videoFlowID}
        onClose={onClose}
      />
    </Modal>
  )
}

export default GenerateThumbnailModal
