import { defineStyleConfig, defineStyle } from '@chakra-ui/styled-system'

const baseStyle = defineStyle(() => ({
  p: '22px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  boxShadow: '0px 5px 14px rgba(0, 0, 0, 0.05)',
  borderRadius: '20px',
  position: 'relative',
  wordWrap: 'break-word',
  backgroundClip: 'border-box',
  bgColor: 'white',

  _dark: {
    bgColor: '#111C44',
  },
}))

const Card = defineStyleConfig({
  baseStyle,
})

export const CardComponent = {
  components: {
    Card,
  },
}
