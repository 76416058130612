// Chakra imports
import { Flex, Link, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { PDMediaLogoLight } from 'components/Icons/Icons'

export default function AuthNavbar(props) {
  const { logoText } = props
  // Chakra color mode
  const mainText = 'white'
  const navbarBg = 'none'
  const navbarBorder = 'none'
  const navbarShadow = 'initial'
  const navbarFilter = 'initial'
  const navbarBackdrop = 'none'
  const navbarPosition = 'absolute'

  const brand = (
    <Link
      href={`${process.env.PUBLIC_URL}`}
      target="_blank"
      display="flex"
      lineHeight="100%"
      fontWeight="bold"
      justifyContent="center"
      alignItems="center"
      color={mainText}
    >
      <Stack direction="row" spacing="12px" align="center" justify="center">
        <PDMediaLogoLight width="50%" height="auto" />
      </Stack>
      <Text fontSize="sm" mt="3px">
        {logoText}
      </Text>
    </Link>
  )
  return (
    <Flex
      position={navbarPosition}
      top="16px"
      left="50%"
      transform="translate(-50%, 0px)"
      background={navbarBg}
      border={navbarBorder}
      boxShadow={navbarShadow}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      borderRadius="15px"
      px="16px"
      py="22px"
      mx="auto"
      width="1044px"
      maxW="90%"
      alignItems="center"
      zIndex="3"
    >
      <Flex w="100%" justifyContent={{ sm: 'start', lg: 'space-between' }}>
        {brand}
      </Flex>
    </Flex>
  )
}
