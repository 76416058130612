import { onAuthStateChanged } from 'firebase/auth'
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react'
import { auth } from '_firebase'

const AuthContext = createContext(null)

AuthContext.displayName = 'AuthContext'

function AuthProvider(props) {
  const [user, setUser] = useState(null)

  // This state is used for showing loading spinner while firebase is checking for auth status
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    onAuthStateChanged(auth, (signedinUser) => {
      setUser(signedinUser)
      setIsLoading(false)
    })
  }, [])

  const value = useMemo(
    () => ({
      user,
      isLoading,
    }),
    [user, isLoading]
  )

  return <AuthContext.Provider {...props} value={value} />
}

const useAuth = () => {
  const ctx = useContext(AuthContext)

  return ctx
}

export { AuthProvider, useAuth }
