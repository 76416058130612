import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'
import React, { useState } from 'react'
import { FaStar } from 'react-icons/fa'
import Lightbox from 'yet-another-react-lightbox'
// eslint-disable-next-line import/no-unresolved
import 'yet-another-react-lightbox/styles.css'

function VideoDetailShortlistRow(props) {
  const [openLightbox, setOpenLightbox] = useState(false)
  const bgColor = useColorModeValue('#F8F9FA', 'navy.900')
  const nameColor = useColorModeValue('gray.900', 'white')
  const {
    name,
    thumbnail,
    images,
    description,
    address,
    url,
    reviewCount,
    rating,
    resultIndex,
    rowIndex,
    score,
    removeResult,
  } = props
  const getLightboxImages = () => {
    const lightboxImages = []
    if (images) {
      Object.values(images)
        .sort((a, b) => a.order - b.order)
        .forEach((image) => {
          lightboxImages.push({ src: image.url })
        })
    }
    return lightboxImages
  }

  return (
    <Box
      p="24px"
      bg={bgColor}
      my="22px"
      borderRadius="12px"
      position="relative"
    >
      <IconButton
        aria-label="Delete result"
        icon={<CloseIcon />}
        position="absolute"
        right="20px"
        top="20px"
        variant="outline"
        fontSize="0.6em"
        onClick={() => removeResult(resultIndex)}
      />
      <Lightbox
        open={openLightbox}
        close={() => setOpenLightbox(false)}
        slides={getLightboxImages()}
      />
      <Flex justify="space-between" w="100%">
        <Flex direction="column" maxWidth="70%">
          <Text color={nameColor} fontSize="md" fontWeight="bold" mb="10px">
            {name}
          </Text>
          <Text color="gray.500" fontSize="sm" fontWeight="semibold">
            Address:{' '}
            <Text as="span" color={nameColor} fontWeight="400">
              {address}
            </Text>
          </Text>
          <Text color="gray.500" mt="8px" fontSize="sm" fontWeight="semibold">
            URL:{' '}
            <Text as="span" color={nameColor} fontWeight="400">
              <Link href={url} target="_blank">
                {url}
              </Link>
            </Text>
          </Text>
          <Text color="gray.500" mt="8px" fontSize="sm" fontWeight="semibold">
            Generated Description:{' '}
            <Text as="span" color={nameColor} fontWeight="400">
              {description}
            </Text>
          </Text>
        </Flex>
        <Flex
          direction={{ sm: 'column', md: 'column' }}
          align="flex-start"
          p={{ md: '24px' }}
        >
          <Flex color="red.500" align="center" p="12px">
            <Icon as={FaStar} me="4px" />
            <Text fontSize="sm" fontWeight="semibold">
              {Number(score).toFixed(3)}
            </Text>
          </Flex>
          <Flex color="gray.500" align="center" p="12px">
            <Flex
              direction={{ sm: 'column', md: 'column' }}
              align="flex-start"
              // p={{ md: "24px" }}
            >
              <Text color="gray.500" fontSize="sm" fontWeight="semibold">
                Rating:{' '}
                <Text as="span" color={nameColor} fontWeight="400">
                  {rating}
                </Text>
              </Text>
              <Text color="gray.500" fontSize="sm" fontWeight="semibold">
                Reviews:{' '}
                <Text as="span" color={nameColor} fontWeight="400">
                  {reviewCount}
                </Text>
              </Text>
              <Text color="gray.500" fontSize="sm" fontWeight="semibold">
                Order:{' '}
                <Text as="span" color={nameColor} fontWeight="400">
                  {resultIndex}
                </Text>
              </Text>
              <Text color="gray.500" fontSize="sm" fontWeight="semibold">
                Index:{' '}
                <Text as="span" color={nameColor} fontWeight="400">
                  {rowIndex}
                </Text>
              </Text>
              <Button
                p="0px"
                mt="24px"
                bg="transparent"
                variant="no-effects"
                onClick={() => setOpenLightbox(true)}
              >
                <Avatar
                  src={thumbnail}
                  w="60px"
                  borderRadius="12px"
                  me="24px"
                />
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

export default VideoDetailShortlistRow
